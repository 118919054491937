import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import '../../assets/css/accounts-page.css';
import { sendMasterArchiveApi } from '../../adminapi';
import {countryList, regionList} from "../../constants";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {getTodayDate} from "../../utils";


const CreateSubscriptionForm = ({handleBack, accountId, setRefreshCounter}) => {

    const [entitlement, setEntitlement] = React.useState("equity");
    const [checkedSource, setCheckedSource] = React.useState(new Set());
    const [countries, setCountries] = React.useState([]);
    const [regions, setRegions] = React.useState([]);
    const [countryInputValue, setCountryInputValue] = React.useState('');
    const [regionInputValue, setRegionInputValue] = React.useState('');


    const [disableMasterBtn, setDisableMasterBtn] = React.useState(false);

    const [apiResponseText, setApiResponseText] = React.useState("")
    const [responseStatus, setResponseStatus] = React.useState(0)

    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState(getTodayDate());
    const [sendEmailFlag, setSendEmailFlag] = React.useState(true);


    const sourceHandleChange = (source) => {
        let newCheckedSource = new Set(checkedSource);
        if (checkedSource.has(source)) {
            newCheckedSource.delete(source);
        } else {
            newCheckedSource.add(source);
        }
        setCheckedSource(new Set(newCheckedSource));
    };

    const sendMasterArchive = async () => {
        setDisableMasterBtn(true)
        // if start date and end date is not of the format yyyy-mm-dd
        if (!startDate.match(/^\d{4}-\d{2}-\d{2}$/) || !endDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
            // check if start date is a valid date
            setResponseStatus(-1)
            setApiResponseText("ERROR : Start and End date should be of the format yyyy-mm-dd")
            setDisableMasterBtn(false)
            return
        }
        // difference between start and end date should be more than 7 days
        if (new Date(endDate) - new Date(startDate) < 691200000) {
            console.log('difference : ', new Date(endDate) - new Date(startDate))
            setResponseStatus(-1)
            setApiResponseText("ERROR : Difference between start and end date should be more than 7 days")
            setDisableMasterBtn(false)
            return
        }
        const request = {
            "account_id": accountId,
            "entitlement": entitlement,
            "sources": entitlement !== 'earnings_transcript' ? Array.from(checkedSource) : [],
            "countries": countries,
            "regions": regions,
            "start_date": startDate,
            "end_date": endDate,
            "send_email": sendEmailFlag
        }
        const response = await sendMasterArchiveApi(request);
        if (response['status'] === 1) {
            setResponseStatus(1)
            setApiResponseText("Generating Archive : " + response['response']['file_status'])
        } else {
            setResponseStatus(-1)
            if (response['message'] !== undefined && response['message'].includes("token")) {
                setApiResponseText("ERROR : " + response['message'] + " Please login again.")
            } else {
                setApiResponseText("ERROR : " + response['message'])
            }
        }
        setRefreshCounter(prev => prev + 1)
        setDisableMasterBtn(false)
    }

    return (
        <div className='pr-4'>
            <div className="flex">
                <div className="flex">
                    <Box sx={{minWidth: 200}} sx={{width: 200, margin: '10px 10px 10px 0'}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Entitlement</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={entitlement}
                                label="Entitlement"
                                onChange={(e) => setEntitlement(e.target.value)}
                            >
                                <MenuItem value={'equity'}>Equity</MenuItem>
                                <MenuItem value={'commodity_v5'}>Commodity V5</MenuItem>
                                <MenuItem value={'economic_index'}>Economic Index</MenuItem>
                                <MenuItem value={'earnings_transcript'}>Earnings Transcript</MenuItem>
                                <MenuItem value={'ftri'}>FTRI</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    {entitlement !== 'earnings_transcript' && entitlement !== 'ftri' && <div className="flex ml-2 mb-4">
                        {['DN', 'BNZ', 'MDT', 'PR'].map((source, index) => (
                            <FormControlLabel
                                label={source}
                                control={
                                    <Checkbox checked={checkedSource.has(source)}
                                              onChange={() => sourceHandleChange(source)}
                                              sx={{
                                                  paddingTop: '1px',
                                                  paddingBottom: '1px',
                                                  paddingRight: '2px',
                                                  paddingLeft: 0
                                              }}
                                    />
                                }
                                sx={{}}
                                key={index}
                            />))}
                    </div>}
                </div>
                {(entitlement === 'equity' || entitlement === 'earnings_transcript') && <div className="flex">
                    <Autocomplete multiple options={countryList} value={countries}
                                  onChange={(event, newValue) => {
                                      setCountries(newValue);
                                  }}
                                  inputValue={countryInputValue}
                                  onInputChange={(event, newInputValue) => {
                                      setCountryInputValue(newInputValue);
                                  }}
                                  renderInput={(params) => (
                                      <TextField
                                          {...params}
                                          label="Countries"
                                          placeholder="multi select"
                                          sx={{width: 200, margin: '10px 10px 10px 0'}}
                                      />
                                  )}
                    />
                    <Autocomplete multiple id="tags-standard" options={regionList} value={regions}
                                  onChange={(event, newValue) => {
                                      setRegions(newValue);
                                  }}
                                  inputValue={regionInputValue}
                                  onInputChange={(event, newInputValue) => {
                                      setRegionInputValue(newInputValue);
                                  }}
                                  renderInput={(params) => (
                                      <TextField
                                          {...params}
                                          label="Regions (Optional)"
                                          placeholder=""
                                          sx={{width: 200, margin: '10px 10px 10px 0'}}
                                      />
                                  )}
                    />
                </div>}
            </div>
            <div className="flex items-start">
                <TextField helperText="YYYY-MM-DD" value={startDate} label="Start Date"
                           onChange={(e) => setStartDate(e.target.value)}
                           sx={{width: 200, margin: '10px 10px 10px 0'}}/>
                <TextField helperText="Enter yesterday's date for latest file" value={endDate} label="End Date"
                           onChange={(e) => setEndDate(e.target.value)}
                           sx={{width: 200, margin: '10px 10px 10px 0'}}/>

            </div>
            <div>
                <Checkbox checked={sendEmailFlag}
                          onChange={() => setSendEmailFlag(sendEmailFlag => !sendEmailFlag)}
                          sx={{
                              paddingTop: '1px',
                              paddingBottom: '1px',
                              paddingRight: '2px',
                              paddingLeft: 0
                          }}
                />
                <label>Send email</label>
            </div>
            <Box sx={{mb: 2}}>
                <div>
                    <Button variant="contained" onClick={sendMasterArchive} sx={{mt: 1, mr: 1}}
                            disabled={disableMasterBtn}>
                        generate archive
                    </Button>
                    <Button onClick={handleBack} sx={{mt: 1, mr: 1}}>
                        Back
                    </Button>
                </div>
                {
                    apiResponseText !== "" &&
                    <div className={`api-response-text ${responseStatus === 1 ? 'success' : 'error'}`}>
                        {apiResponseText}
                    </div>
                }
            </Box>
        </div>

    );
};

export default CreateSubscriptionForm;