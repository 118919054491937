import React, { useRef, useEffect } from 'react';

const SentimentLine = ({ sentiment }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    canvas.style.backgroundColor = 'lightgrey';
    canvas.style.borderRadius = '8px';

    // Clear the canvas
    context.clearRect(0, 0, canvas.width, canvas.height);

    // Calculate the length of the line based on the sentiment value
    const lineLength = Math.abs(sentiment) * canvas.width;

    // Calculate the x-coordinate of the line's endpoint
    const x = (sentiment >= 0) ? canvas.width / 2 + lineLength / 2 : canvas.width / 2 - lineLength / 2;
    context.strokeStyle = (sentiment >= 0) ? 'green' : 'red';

    // Draw the line
    context.beginPath();
    context.moveTo(canvas.width / 2, canvas.height / 2);
    context.lineTo(x, canvas.height / 2);
    // context.strokeStyle = 'black';
    context.lineWidth = 8;
    context.stroke();
  }, [sentiment]);

  return <div className='flex items-center'>
    <div className='w-5'>{sentiment}</div>
    <div className='ml-8'>
      <canvas ref={canvasRef} width={100} height={5} />
    </div>
  </div>;
};

export default SentimentLine;