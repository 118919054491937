import * as React from 'react';
import '../../assets/css/accounts-page.css';
import {
    getFilesByAccount, sendFileEmail,
} from '../../adminapi';
import {DataGrid} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import {useEffect} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const ClientFileTable = ({handleBack, changeAccountId, refreshCounter, parAccountId}) => {

    const [files, setFiles] = React.useState([]);
    const [accountID, setAccountID] = React.useState(parAccountId);
    const [showEmptyError, setShowEmptyError] = React.useState(false)
    const [user, setUser] = React.useState({})

    useEffect(() => {
        handleAccoundIdSearch({key: 'Enter'})
    }, [refreshCounter]);

    useEffect(() => {
        setAccountID(parAccountId)
        // handleAccoundIdSearch({key: 'Enter'})
    }, [parAccountId])

    const handleAccoundIdSearch = (e) => {
        if (e.key === 'Enter') {
            if (accountID === undefined || accountID === null || accountID === '') {
                return
            }
            changeAccountId(accountID)
            getFilesByAccount(accountID).then((response) => {
                if (response['status'] === 1) {
                    const response_files = response['response']['files']
                    setUser(response['response']['user'])
                    if (response_files.length === 0) {
                        setShowEmptyError(true)
                        setFiles([])
                    } else {
                        setFiles(response['response']['files'])
                        setShowEmptyError(false)
                    }
                } else if (response['status'] === -1 && response['message'].includes("token")) {
                    handleBack()
                } else if (response['status'] === -1) {
                    setShowEmptyError(true)
                    setFiles([])
                }
            })
        }
    }

    const handleButtonClick = (file) => {
        sendFileEmail( file['file_id'], file['subscription_id']).then((response) => {
            if (response['status'] === 1) {
                alert('Email sent successfully')
            } else if (response['status'] === -1 && response['message'].includes("token")) {
                handleBack()
            }
        })
    }

    const columns = [
        {field: 'file_id', headerName: 'File Name(.csv)', width: 280},
        {field: 'subscription_id', headerName: 'Service', width: 260},
        {field: 'file_start_time', headerName: 'Start Time', width: 180},
        {field: 'file_end_time', headerName: 'End Time', width: 180},
        {field: 'file_creation_time', headerName: 'Creation Time', width: 180},
        {field: 'file_download_time', headerName: 'Download Time', width: 180},
        {field: 'resend_link', headerName: 'Resent Link', width: 180, renderCell: (params) => (
                <button onClick={() => handleButtonClick(params.row)}>Resend Email</button>
            ),},
    ];

    return (
        <div className='pr-4'>
            <div className="flex items-start">
                <TextField helperText="Press Enter to show files"
                           value={accountID}
                           onChange={(e) => setAccountID(e.target.value.toUpperCase())}
                           label="Account ID"
                           sx={{width: 200, margin: '10px 10px 10px 0'}}
                           onKeyDown={(e) => handleAccoundIdSearch(e)}
                />
                {user && user['email'] !== undefined && <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Account ID</TableCell>
                                <TableCell>Password</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Company</TableCell>
                                <TableCell>Poc Name</TableCell>
                                <TableCell>Poc Email</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell >{user['account_id']}</TableCell>
                                <TableCell >{user['password']}</TableCell>
                                <TableCell component="th" scope="row">{user['email']}</TableCell>
                                <TableCell>{user['name']}</TableCell>
                                <TableCell >{user['company_name']}</TableCell>
                                <TableCell >{user['poc_name']}</TableCell>
                                <TableCell >{user['poc_email']}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>}
            </div>
            {files && files.length > 0 && <div style={{height: 400, width: '100%'}}>
                <DataGrid
                    rows={files}
                    columns={columns}
                    getRowId={(row) => row.request_time}
                    key={files.length}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'file_creation_time', sort: 'asc' }],
                        },
                    }}
                />
            </div>}
            {showEmptyError && accountID && <div className='api-response-text error'>No files found for this account</div>}
        </div>

    );
};

export default ClientFileTable;