import React, { useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_more from 'highcharts/highcharts-more'
import exporting from 'highcharts/modules/exporting'
import exportData from 'highcharts/modules/export-data'
import accessibility from 'highcharts/modules/accessibility'
import HighchartsWordCloud from "highcharts/modules/wordcloud";

HC_more(Highcharts)
exporting(Highcharts)
exportData(Highcharts)
accessibility(Highcharts)
HighchartsWordCloud(Highcharts)

Highcharts.seriesTypes.wordcloud.prototype.deriveFontSize = function(
    relativeWeight
  ) {
    var maxFontSize = 20;
    // Will return a fontSize between 0px and 25px.
    return Math.floor(maxFontSize * relativeWeight + 5);
  };


export default function WordCloud({chartData, handlePointClick}) {
    const chartRef = useRef(null);
    const options = {
        series: [{
            type: 'wordcloud',
            data: chartData,
            name: 'Occurrences',
            colors: ['#2f7ed8'],
            rotation: {
                from: 0,
                to: 0,
            },
            point: {
                events: {
                    click: function (event) {
                        handlePointClick(event.point.name)
                    }
                }
            }
        }],
        title: {
            text: 'Daily Word Count',
            align: 'left',
            y: 20,
            style: {
                fontSize: '18px',
                fontFamily: 'Roboto',
                fontWeight: '500',
            }
        },
        tooltip: {
            useHTML: true,
            shared: false,
            formatter: function(tooltip) {
                return '<div class="my-tooltip" >' +
                '<h3>' + this.point.name + '</h3>' +
                '<p style="font-size: 14px">OCCURENCES: <strong>' + this.point.weight + '</strong></p>' +
                '</div>';
            }
        },
        credits: {
            enabled: false
        },
        lang: {
            noData: "No data for the period selected"
        }
    }
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
            containerProps={{ style: { width: '98%' } }}
        />
    );
}