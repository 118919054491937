import React, { useEffect, useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_more from 'highcharts/highcharts-more'
import exporting from 'highcharts/modules/exporting'
import exportData from 'highcharts/modules/export-data'
import accessibility from 'highcharts/modules/accessibility'
HC_more(Highcharts)
exporting(Highcharts)
exportData(Highcharts)
accessibility(Highcharts)

const getMaxSentiment = (sentiments) => {
    if (sentiments === undefined || sentiments.length === 0) {
        return 0;
    }
    var max = 0;
    sentiments.forEach((sentiment) => {
        if (Math.abs(sentiment.y) > max) {
            max = Math.abs(sentiment.y);
        }
    });
    return max;
}


export default function ColumnLinePieChart({ chartData, onColumnClick }) {
    const max = getMaxSentiment(chartData.sentiments) + 0.2
    const chartRef = useRef(null);
    useEffect(() => {
        if (chartRef.current) {
            const chart = chartRef.current.chart;
            if (chart) {
                chart.series[0].points.forEach((point, index) => {
                    point.graphic.element.addEventListener('click', () => {
                        const date = chartData.dates[index];
                        onColumnClick(date);
                    });
                });
            }
        }
    }, [chartData, onColumnClick]);
    const options = {
        title: {
            text: 'Sentiment and Price Chart',
            align: 'left',
            y: 20,
            style: {
                fontSize: '18px',
                fontFamily: 'Roboto',
                fontWeight: '500',
            }
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            categories: chartData.dates
        },
        yAxis: [{
            title: {
                text: 'Sentiment'
            },
            plotLines: [{
                value: 0,
                width: 2,
                color: 'grey',
                dashStyle: 'dash'
            }],
            // max: max,
            // min: -max
        }, {
            title: {
                text: 'Price'
            },
            opposite: true
        }],
        tooltip: {
            formatter: function () {
                var tooltip = '<b>' + this.key + '</b><br/>';
                if (this.key === 'Positive' || this.key === 'Negative' || this.key === 'Neutral') {
                    tooltip += '<span style="color:' + '">\u25CF</span> ' + '<b> Count: ' + this.point.y.toFixed(2) + '</b><br/>';
                } else {
                    const sentiment = chartData.sentiments.at(this.point.x).y
                    const price = chartData.prices.at(this.point.x)
                    if (sentiment) {
                        tooltip += '<span style="color:' + '">\u25CF</span> ' + '<b> Sentiment: ' + sentiment.toFixed(2) + '</b><br/>';
                    }
                    if (price) {
                        tooltip += '<span style="color:' + '">\u25CF</span> ' + '<b> Price: ' + price.toFixed(2) + '</b><br/>';
                    }
                }
                return tooltip;
            }
        },
        plotOptions: {
            series: {
                borderRadius: '25%',
                lineWidth: 2,
                lineColor: '#5794F2',
            }
        },
        series: [{
            type: 'column',
            name: 'Sentiment',
            data: chartData.sentiments
        }, {
            type: 'spline',
            name: 'Price',
            data: chartData.prices,
            yAxis: 1,
            marker: {
                enabled: false
            }
        }
        ],
        credits: {
            enabled: false
        }
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
            containerProps={{ style: { width: '98%' } }}
        />
    );
}