import React from 'react'
import TickerChart from '../components/universe-page/TickerChart'
import EventChart from '../components/universe-page/EventChart'
import WordChart from '../components/universe-page/WordChart'
import TopSentimentTable from '../components/universe-page/TopSentimentTable';


export default function UniversePage() {

    return (
        <div className="universe-container">
            <TickerChart />
            <div className='flex'>
                <EventChart />
                <WordChart />
            </div>
            <TopSentimentTable />
        </div>
    );
}
