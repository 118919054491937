import React, { useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_more from 'highcharts/highcharts-more'
import exporting from 'highcharts/modules/exporting'
import exportData from 'highcharts/modules/export-data'
import accessibility from 'highcharts/modules/accessibility'
HC_more(Highcharts)
exporting(Highcharts)
exportData(Highcharts)
accessibility(Highcharts)


export default function BubbleChart(props) {
    const { chartData, plotData } = props;
    const chartRef = useRef(null);
    const options = {
        chart: {
            type: 'bubble',
            plotBorderWidth: 0,
            zoomType: 'xy',
        },

        legend: {
            enabled: false
        },

        title: {
            text: 'Company Sentiment & Return Rankings',
            align: 'left',
            y: 20,
            style: {
                fontSize: '18px',
                fontFamily: 'Roboto',
                fontWeight: '500',
            }
        },
        xAxis: {
            gridLineWidth: 1,
            crosshair: true,
            title: {
                text: 'Sentiment Rank'
            },
            labels: {
                formatter: function() {
                    let val = this.value + 1;
                    // limit decimal places to 2
                    return val.toFixed(2);
                  },
            },
            reversed: true,
            min: -1,
        },
        yAxis: {
            startOnTick: false,
            endOnTick: false,
            title: {
                text: 'Return Rank'
            },
            labels: {
                formatter: function() {
                    let val = this.value + 1;
                    return val.toFixed(2);
                  },
            },
            reversed: true,
            min: -1,
            offset: 0,
            crosshair: true,
            // max: 100,
        },

        tooltip: {
            useHTML: true,
            shared: false,
            formatter: function(tooltip) {
                return '<div class="my-tooltip">' +
                '<h3>' + this.point.ticker + '</h3>' +
                '<p style="font-size: 14px"><strong>' + this.point.name + '</strong></p>' +
                '<p><strong>Return:</strong> ' + (this.point.return*100).toFixed(2) + '%</p>' +
                '<p><strong>Sentiment:</strong> ' + this.point.sentiment.toFixed(2) + '</p>' +
                '<p><strong>Volume:</strong> ' + this.point.volume + '</p>' +
                '<p><strong>Sentiment Rank:</strong> ' + (this.point.x + 1) + '</p>' +
                '<p><strong>Return Rank:</strong> ' + (this.point.y + 1) + '</p>' +
                '</div>';
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: false,
                    format: '{point.name}'
                }
            }
        },
        series: [
            {
                type: 'bubble',
                maxSize: '10%',
                data: chartData,
                point: {
                    events: {
                      click: function () {
                        window.location.href = `/ticker/${this.ticker}`;
                      }
                    }
                  }
            }
        ]
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
            containerProps={{ style: { width: '98%' } }}
        /> 
    );
}
