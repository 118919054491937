import * as React from 'react';
import '../assets/css/accounts-page.css';
import {
     getAllUsers,
} from '../adminapi';
import {DataGrid} from "@mui/x-data-grid";
import {useEffect} from "react";


const UserListPage = () => {
    const [userList, setUserList] = React.useState([]);

    useEffect(() => {
        if (userList.length === 0) {
            getAllUsers().then((response) => {
                if (response['status'] === 1) {
                    setUserList(response['response']['users'])
                } else {
                    console.log('error fetching users')
                }
            })
        }
    }, [userList])

    const columns = [
        {field: 'id', headerName: 'id', width: 80},
        {field: 'account_id', headerName: 'account id', width: 100},
        {field: 'password', headerName: 'password', width: 200},
        {field: 'email', headerName: 'email', width: 280},
        {field: 'name', headerName: 'name', width: 180},
        {field: 'company_name', headerName: 'company name', width: 180},
        {field: 'created_on', headerName: 'created on', width: 180},
        {field: 'poc_name', headerName: 'poc name', width: 120},
        {field: 'poc_email', headerName: 'poc email', width: 280}
    ];

    return (
        <div className='page-container'>
            <div className="text-xl">Accounts</div>
            {userList.length > 0 && <DataGrid
                rows={userList}
                columns={columns}
                getRowId={(row) => row.id}
                key={userList.length}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'created_on', sort: 'desc' }],
                    },
                }}
            />}
        </div>
    );
};

export default UserListPage;