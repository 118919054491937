import {baseUrl} from "./constants";

export const adminLogin = async (username, password) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({"username": username, "password": password});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        const response = await fetch(`${baseUrl}/cras/api/v1/login`, requestOptions)
        if (!response.ok) {
            const fail_res = await response.json()
            console.log(fail_res)
            alert(fail_res['message'])
            return {};
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const getAuthToken = async () => {
    const username = localStorage.getItem('admin.username');
    const password = localStorage.getItem('admin.password');
    const decryptedUsername = atob(username);
    const decryptedPassword = atob(password);
    const response = await adminLogin(decryptedUsername.toUpperCase(), decryptedPassword);
    if (response !== {} && response['status'] === 1) {
        return response['response']['auth_token']
    } else {
        return ''
    }
}

export const createNewUser = async (request) => {
    try {
        const authToken = await getAuthToken();
        let requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + authToken,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(request)
        };

        const response = await fetch(`${baseUrl}/cras/api/v1/admin/user`, requestOptions);
        if (!response.ok) {
            console.error('Network response was not ok.');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

// export const createNewSubscription = async (authToken, request) => {
//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": "Bearer " + authToken,
//                 "Access-Control-Allow-Origin": "*",
//                 "Content-Type": "application/json"
//             },
//             body: JSON.stringify(request)
//         };
//
//         const response = await fetch(`${baseUrl}/cras/api/v1/admin/subscription`, requestOptions);
//         if (!response.ok) {
//             console.error('Network response was not ok.');
//         }
//         return await response.json();
//     } catch (error) {
//         console.error('Error fetching data:', error);
//     }
// }

// export const getSubscriptionsApi = async (authToken, accountId) => {
//     try {
//         var requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": "Bearer " + authToken,
//                 "Access-Control-Allow-Origin": "*"
//             }
//         };
//         const response = await fetch(`${baseUrl}/cras/api/v1/admin/subscription?account_id=` + accountId, requestOptions);
//         if (!response.ok) {
//             const fail_res = await response.json()
//             console.log(fail_res)
//             alert(fail_res['message'])
//             return {'status': 'failure'};
//         }
//         return await response.json();
//     } catch (error) {
//         console.error('Error fetching data:', error);
//     }
// }

export const sendMasterArchiveApi = async (request) => {
    try {
        const authToken = await getAuthToken();
        var requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + authToken,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(request)
        };

        const response = await fetch(`${baseUrl}/cras/api/v1/admin/portal/data`, requestOptions);
        if (!response.ok) {
            console.error('Network response was not ok.');
            return {'status': 'failure'};
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

// export const sendPatchFileApi = async (authToken, subId) => {
//     try {
//         var requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": "Bearer " + authToken,
//                 "Access-Control-Allow-Origin": "*"
//             }
//         };
//         const response = await fetch(`${baseUrl}/cras/api/v1/data/patch_file?subscription_id=` + subId, requestOptions);
//         if (!response.ok) {
//             const fail_res = await response.json()
//             console.log(fail_res)
//             alert(fail_res['message'])
//         }
//         return await response.json();
//     } catch (error) {
//         console.error('Error fetching data:', error);
//     }
// }

export const getFilesByAccount = async ( accountId) => {
    if (accountId === undefined || accountId === null || accountId === '') {
        return {'status': -1, "message": "account id is empty"};
    }
    try {
        const authToken = await getAuthToken();
        var requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + authToken,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({"account_id" : accountId})
        };

        const response = await fetch(`${baseUrl}/cras/api/v1/admin/user/files`, requestOptions);
        if (!response.ok) {
            console.error('Network response was not ok.');
            return await response.json();
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const sendFileEmail = async ( fileId, subscriptionId) => {
    if (fileId === undefined || subscriptionId === null || fileId === '') {
        return {'status': -1, "message": "account id is empty"};
    }
    try {
        const authToken = await getAuthToken();

        var requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + authToken,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({"file_id" : fileId, "subscription_id" : subscriptionId})
        };

        const response = await fetch(`${baseUrl}/cras/api/v1/admin/user/files/email`, requestOptions);
        if (!response.ok) {
            console.error('Network response was not ok.');
            return await response.json();
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const getAllUsers = async () => {
    try {
        var requestOptions = {
            method: 'GET',
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        const response = await fetch(`${baseUrl}/cras/api/v1/admin/user`, requestOptions);
        if (!response.ok) {
            const fail_res = await response.json()
            console.log(fail_res)
            alert(fail_res['message'])
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

// export const searchClientApi = async (authToken, accountId) => {
//     try {
//         var requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": "Bearer " + authToken,
//                 "Access-Control-Allow-Origin": "*"
//             }
//         };
//
//         const response = await fetch(`${baseUrl}/cras/api/v1/users?account_id=` + accountId, requestOptions);
//         if (!response.ok) {
//             const fail_res = await response.json()
//             console.log(fail_res)
//             alert(fail_res['message'])
//             return {'status': 'failure'};
//         }
//         return await response.json();
//     } catch (error) {
//         console.error('Error fetching data:', error);
//     }
// }