import {baseUrl} from './constants'
import {isCallEnt, isNewsEnt, isWebNewsEnt} from "./utils";

export const getTopN = async (requestBody) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        };
        const response = await fetch(`${baseUrl}/topn`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Rethrow the error to be caught by the caller if needed
    }
};

export const getEvents = async (requestBody) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        };
        const response = await fetch(`${baseUrl}/getEventHeatMapDashboard`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const getEventsForCompany = async (requestBody) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        };
        const response = await fetch(`${baseUrl}/getEventHeatMap`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const getWordCount = async (requestBody) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        };
        const response = await fetch(`${baseUrl}/getWordCountDaily`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const getDailyReports = async (requestBody, type) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify(requestBody),
        };
        var url = '';
        if (type === 'word' && requestBody.value.length > 0) {
            url = `${baseUrl}/trends/word`;
        } else {
            url = `${baseUrl}/trends/event`;
        }
        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const getUserPortfolio = async () => {
    if (process.env.NODE_ENV === "development") {
        return {
            "portfolio": [
                {
                    "symbols": [
                        "500355-IND",
                        "507717-IND",
                        "512070-IND",
                        "523642-IND",
                        "538666-IND",
                        "532497-IND",
                        "538962-IND",
                        "532432-IND",
                        "532478-IND",
                        "500477-IND",
                        "502355-IND",
                        "532796-IND",
                        "517168-IND",
                        "517206-IND",
                        "520051-IND",
                        "532527-IND",
                        "532605-IND",
                        "505192-IND",
                        "500008-IND",
                        "532977-IND",
                        "505200-IND",
                        "500086-IND",
                        "500182-IND",
                        "532500-IND",
                        "530759-IND",
                        "532343-IND",
                        "500877-IND",
                        "500878-IND",
                        "532509-IND",
                        "500495-IND",
                        "500493-IND",
                        "500520-IND",
                        "515030-IND",
                        "541163-IND",
                        "540124-IND",
                        "541578-IND",
                        "543276-IND",
                        "530367-IND",
                        "532215-IND",
                        "532134-IND",
                        "532483-IND",
                        "532210-IND",
                        "532772-IND",
                        "500469-IND",
                        "500180-IND",
                        "532174-IND",
                        "532814-IND",
                        "532187-IND",
                        "500247-IND",
                        "540065-IND",
                        "500112-IND",
                        "542867-IND",
                        "531344-IND",
                        "522287-IND",
                        "532714-IND",
                        "500510-IND",
                        "517174-IND",
                        "532683-IND",
                        "500480-IND",
                        "500411-IND",
                        "500049-IND",
                        "532178-IND",
                        "540678-IND",
                        "542011-IND",
                        "541154-IND",
                        "500002-IND",
                        "500550-IND",
                        "543237-IND",
                        "541143-IND",
                        "543187-IND",
                        "500260-IND",
                        "500410-IND",
                        "500425-IND",
                        "532644-IND",
                        "500380-IND",
                        "532538-IND",
                        "502090-IND",
                        "500387-IND",
                        "500335-IND",
                        "500292-IND",
                        "540575-IND",
                        "542216-IND",
                        "500027-IND",
                        "524208-IND",
                        "524200-IND",
                        "532504-IND",
                        "506655-IND",
                        "503806-IND",
                        "532827-IND",
                        "500331-IND",
                        "533229-IND",
                        "500825-IND",
                        "500830-IND",
                        "500096-IND",
                        "531162-IND",
                        "532424-IND",
                        "500696-IND",
                        "500875-IND",
                        "531642-IND",
                        "500043-IND",
                        "530517-IND",
                        "500114-IND",
                        "500820-IND",
                        "509480-IND",
                        "500165-IND",
                        "533155-IND",
                        "543258-IND",
                        "539542-IND",
                        "500790-IND",
                        "543248-IND",
                        "500067-IND",
                        "500575-IND",
                        "517354-IND",
                        "532953-IND",
                        "540699-IND",
                        "542652-IND",
                        "540902-IND",
                        "541301-IND",
                        "517569-IND",
                        "539876-IND",
                        "500850-IND",
                        "541233-IND",
                        "532811-IND",
                        "540047-IND",
                        "541019-IND",
                        "532940-IND",
                        "532942-IND",
                        "500294-IND",
                        "539150-IND",
                        "540544-IND",
                        "540005-IND",
                        "539289-IND",
                        "532370-IND",
                        "531209-IND",
                        "532809-IND",
                        "532440-IND",
                        "500304-IND",
                        "532540-IND",
                        "500209-IND",
                        "507685-IND",
                        "532281-IND",
                        "532755-IND",
                        "532466-IND",
                        "532541-IND",
                        "526299-IND",
                        "538835-IND",
                        "533179-IND",
                        "542752-IND",
                        "543280-IND",
                        "533320-IND",
                        "532819-IND",
                        "505537-IND",
                        "532733-IND",
                        "533278-IND",
                        "500440-IND",
                        "500295-IND",
                        "532286-IND",
                        "500228-IND",
                        "532234-IND",
                        "526371-IND",
                        "500113-IND",
                        "590078-IND",
                        "543299-IND",
                        "500470-IND",
                        "500870-IND",
                        "538567-IND",
                        "532514-IND",
                        "539336-IND",
                        "532702-IND",
                        "532522-IND",
                        "539957-IND",
                        "532155-IND",
                        "532331-IND",
                        "539523-IND",
                        "532321-IND",
                        "500087-IND",
                        "500124-IND",
                        "524494-IND",
                        "506943-IND",
                        "500257-IND",
                        "524715-IND",
                        "500420-IND",
                        "532488-IND",
                        "543064-IND",
                        "540222-IND",
                        "539268-IND",
                        "531768-IND",
                        "539524-IND",
                        "542650-IND",
                        "509930-IND",
                        "532830-IND",
                        "500940-IND",
                        "532898-IND",
                        "532555-IND",
                        "540376-IND",
                        "534976-IND",
                        "500251-IND",
                        "535755-IND",
                        "500038-IND",
                        "532356-IND",
                        "534816-IND",
                        "532454-IND",
                        "590122-IND",
                        "590086-IND",
                        "590082-IND",
                        "590075-IND",
                        "590073-IND",
                        "590072-IND",
                        "590071-IND",
                        "590070-IND",
                        "590066-IND",
                        "590062-IND",
                        "590056-IND",
                        "590051-IND",
                        "590031-IND",
                        "590030-IND",
                        "590022-IND",
                        "590021-IND",
                        "590018-IND",
                        "590013-IND",
                        "590006-IND",
                        "590005-IND",
                        "590003-IND",
                        "543275-IND",
                        "543265-IND",
                        "543245-IND",
                        "543241-IND",
                        "543066-IND",
                        "542759-IND",
                        "542141-IND",
                        "541770-IND",
                        "541700-IND",
                        "541450-IND",
                        "540935-IND",
                        "540900-IND",
                        "540777-IND",
                        "540719-IND",
                        "540595-IND",
                        "540212-IND",
                        "540180-IND",
                        "540173-IND",
                        "540133-IND",
                        "540115-IND",
                        "539889-IND",
                        "539871-IND",
                        "539448-IND",
                        "539314-IND",
                        "539313-IND",
                        "539312-IND",
                        "539309-IND",
                        "539302-IND",
                        "539300-IND",
                        "539291-IND",
                        "539290-IND",
                        "539288-IND",
                        "539287-IND",
                        "539276-IND",
                        "539275-IND",
                        "539274-IND",
                        "539273-IND",
                        "539265-IND",
                        "539255-IND",
                        "539254-IND",
                        "539253-IND",
                        "539252-IND",
                        "539235-IND",
                        "539230-IND",
                        "539229-IND",
                        "539227-IND",
                        "539223-IND",
                        "539222-IND",
                        "539221-IND",
                        "539220-IND",
                        "539219-IND",
                        "539218-IND",
                        "539206-IND",
                        "539201-IND",
                        "539199-IND",
                        "539198-IND",
                        "539197-IND",
                        "539196-IND",
                        "539195-IND",
                        "539176-IND",
                        "539175-IND",
                        "539174-IND",
                        "539151-IND",
                        "539149-IND",
                        "539148-IND",
                        "539141-IND",
                        "539132-IND",
                        "539126-IND",
                        "539124-IND",
                        "539122-IND",
                        "539121-IND",
                        "539120-IND",
                        "539119-IND",
                        "539118-IND",
                        "539116-IND",
                        "539115-IND",
                        "539113-IND",
                        "539112-IND",
                        "539111-IND",
                        "539110-IND",
                        "539099-IND",
                        "539098-IND",
                        "539096-IND",
                        "539090-IND",
                        "539083-IND",
                        "539045-IND",
                        "539044-IND",
                        "539042-IND",
                        "539040-IND",
                        "539032-IND",
                        "539031-IND",
                        "539026-IND",
                        "539018-IND",
                        "539017-IND",
                        "539016-IND",
                        "539015-IND",
                        "539013-IND",
                        "539012-IND",
                        "539011-IND",
                        "539006-IND",
                        "538993-IND",
                        "538992-IND",
                        "538987-IND",
                        "538979-IND",
                        "538975-IND",
                        "538965-IND",
                        "538964-IND",
                        "538943-IND",
                        "538942-IND",
                        "538935-IND",
                        "538928-IND",
                        "538926-IND",
                        "538923-IND",
                        "538921-IND",
                        "538920-IND",
                        "538919-IND",
                        "538918-IND",
                        "538897-IND",
                        "538896-IND",
                        "538895-IND",
                        "538894-IND",
                        "538890-IND",
                        "538881-IND",
                        "538875-IND",
                        "538868-IND",
                        "538863-IND",
                        "538862-IND",
                        "538860-IND",
                        "538837-IND",
                        "538836-IND",
                        "538834-IND",
                        "538833-IND",
                        "538817-IND",
                        "538812-IND",
                        "538794-IND",
                        "538789-IND",
                        "538786-IND",
                        "538778-IND",
                        "538777-IND",
                        "538770-IND",
                        "538765-IND",
                        "538733-IND",
                        "538732-IND",
                        "538730-IND",
                        "538716-IND",
                        "538715-IND",
                        "538708-IND",
                        "538706-IND",
                        "538685-IND",
                        "538674-IND",
                        "538668-IND",
                        "538652-IND",
                        "538647-IND",
                        "538635-IND",
                        "538634-IND",
                        "538610-IND",
                        "538609-IND",
                        "538598-IND",
                        "538597-IND",
                        "538596-IND",
                        "538576-IND",
                        "538569-IND",
                        "538568-IND",
                        "538564-IND",
                        "538563-IND",
                        "538562-IND",
                        "538557-IND",
                        "538556-IND",
                        "538546-IND",
                        "538542-IND",
                        "538539-IND",
                        "538537-IND",
                        "538521-IND",
                        "538496-IND",
                        "538476-IND",
                        "538464-IND",
                        "538452-IND",
                        "538402-IND",
                        "538382-IND",
                        "538365-IND",
                        "538351-IND",
                        "538319-IND",
                        "538273-IND",
                        "538268-IND",
                        "538212-IND",
                        "538180-IND",
                        "538081-IND",
                        "538019-IND",
                        "537985-IND",
                        "537840-IND",
                        "537839-IND",
                        "537820-IND",
                        "537784-IND",
                        "537766-IND",
                        "537750-IND",
                        "537707-IND",
                        "537582-IND",
                        "537573-IND",
                        "537536-IND",
                        "537492-IND",
                        "537392-IND",
                        "537326-IND",
                        "537292-IND",
                        "537291-IND",
                        "537259-IND",
                        "537253-IND",
                        "537069-IND",
                        "536974-IND",
                        "536965-IND",
                        "536738-IND",
                        "536737-IND",
                        "536710-IND",
                        "536672-IND",
                        "536659-IND",
                        "536507-IND",
                        "536493-IND",
                        "536456-IND",
                        "536264-IND",
                        "535916-IND",
                        "535789-IND",
                        "535754-IND",
                        "535730-IND",
                        "535719-IND",
                        "535693-IND",
                        "535667-IND",
                        "535657-IND",
                        "535648-IND",
                        "535621-IND",
                        "535620-IND",
                        "535602-IND",
                        "535601-IND",
                        "535566-IND",
                        "535458-IND",
                        "535431-IND",
                        "535387-IND",
                        "535322-IND",
                        "535276-IND",
                        "535267-IND",
                        "534809-IND",
                        "534758-IND",
                        "534748-IND",
                        "534742-IND",
                        "534741-IND",
                        "534732-IND",
                        "534731-IND",
                        "534691-IND",
                        "534680-IND",
                        "534675-IND",
                        "534659-IND",
                        "534639-IND",
                        "534623-IND",
                        "534615-IND",
                        "534612-IND",
                        "534600-IND",
                        "534598-IND",
                        "534563-IND",
                        "534425-IND",
                        "534422-IND",
                        "534392-IND",
                        "534369-IND",
                        "534338-IND",
                        "534328-IND",
                        "534312-IND",
                        "534190-IND",
                        "534139-IND",
                        "534091-IND",
                        "534076-IND",
                        "534064-IND",
                        "534063-IND",
                        "534060-IND",
                        "533982-IND",
                        "533941-IND",
                        "533896-IND",
                        "533761-IND",
                        "533758-IND",
                        "533704-IND",
                        "533676-IND",
                        "533655-IND",
                        "533644-IND",
                        "533632-IND",
                        "533629-IND",
                        "533608-IND",
                        "533602-IND",
                        "533581-IND",
                        "533576-IND",
                        "533573-IND",
                        "533553-IND",
                        "533543-IND",
                        "533482-IND",
                        "533477-IND",
                        "533470-IND",
                        "533451-IND",
                        "533427-IND",
                        "533411-IND",
                        "533407-IND",
                        "533398-IND",
                        "533393-IND",
                        "533344-IND",
                        "533343-IND",
                        "533339-IND",
                        "533336-IND",
                        "533333-IND",
                        "533329-IND",
                        "533306-IND",
                        "533303-IND",
                        "533302-IND",
                        "533301-IND",
                        "533298-IND",
                        "533296-IND",
                        "533294-IND",
                        "533293-IND",
                        "533292-IND",
                        "533287-IND",
                        "533286-IND",
                        "533284-IND",
                        "533282-IND",
                        "533275-IND",
                        "533274-IND",
                        "533273-IND",
                        "533271-IND",
                        "533270-IND",
                        "533269-IND",
                        "533268-IND",
                        "533267-IND",
                        "533265-IND",
                        "533263-IND",
                        "533262-IND",
                        "533261-IND",
                        "533260-IND",
                        "533257-IND",
                        "533252-IND",
                        "533248-IND",
                        "533239-IND",
                        "533227-IND",
                        "533221-IND",
                        "533217-IND",
                        "533212-IND",
                        "533210-IND",
                        "533208-IND",
                        "533207-IND",
                        "533206-IND",
                        "533193-IND",
                        "533181-IND",
                        "533170-IND",
                        "533169-IND",
                        "533168-IND",
                        "533167-IND",
                        "533166-IND",
                        "533163-IND",
                        "533161-IND",
                        "533160-IND",
                        "533158-IND",
                        "533157-IND",
                        "533156-IND",
                        "533152-IND",
                        "533151-IND",
                        "533150-IND",
                        "533149-IND",
                        "533148-IND",
                        "533146-IND",
                        "533138-IND",
                        "533137-IND",
                        "533122-IND",
                        "533121-IND",
                        "533108-IND",
                        "533106-IND",
                        "533104-IND",
                        "533101-IND",
                        "533098-IND",
                        "533096-IND",
                        "533093-IND",
                        "533090-IND",
                        "533088-IND",
                        "533080-IND",
                        "533078-IND",
                        "533056-IND",
                        "533048-IND",
                        "533033-IND",
                        "533029-IND",
                        "533023-IND",
                        "533022-IND",
                        "533019-IND",
                        "533018-IND",
                        "533014-IND",
                        "533012-IND",
                        "533007-IND",
                        "533001-IND",
                        "532994-IND",
                        "532993-IND",
                        "532992-IND",
                        "532989-IND",
                        "532988-IND",
                        "532986-IND",
                        "532983-IND",
                        "532978-IND",
                        "532976-IND",
                        "532972-IND",
                        "532967-IND",
                        "532966-IND",
                        "532959-IND",
                        "532957-IND",
                        "532951-IND",
                        "532947-IND",
                        "532946-IND",
                        "532945-IND",
                        "532944-IND",
                        "532941-IND",
                        "532939-IND",
                        "532937-IND",
                        "532935-IND",
                        "532934-IND",
                        "532933-IND",
                        "532932-IND",
                        "532931-IND",
                        "532930-IND",
                        "532929-IND",
                        "532928-IND",
                        "532927-IND",
                        "532926-IND",
                        "532925-IND",
                        "532924-IND",
                        "532922-IND",
                        "532921-IND",
                        "532918-IND",
                        "532916-IND",
                        "532915-IND",
                        "532906-IND",
                        "532899-IND",
                        "532896-IND",
                        "532894-IND",
                        "532893-IND",
                        "532892-IND",
                        "532890-IND",
                        "532889-IND",
                        "532888-IND",
                        "532885-IND",
                        "532884-IND",
                        "532880-IND",
                        "532879-IND",
                        "532878-IND",
                        "532875-IND",
                        "532872-IND",
                        "532869-IND",
                        "532868-IND",
                        "532867-IND",
                        "532865-IND",
                        "532864-IND",
                        "532859-IND",
                        "532856-IND",
                        "532855-IND",
                        "532854-IND",
                        "532853-IND",
                        "532852-IND",
                        "532851-IND",
                        "532850-IND",
                        "532848-IND",
                        "532847-IND",
                        "532845-IND",
                        "532843-IND",
                        "532842-IND",
                        "532841-IND",
                        "532839-IND",
                        "532835-IND",
                        "532834-IND",
                        "532832-IND",
                        "532828-IND",
                        "532826-IND",
                        "532825-IND",
                        "532822-IND",
                        "532817-IND",
                        "532815-IND",
                        "532812-IND",
                        "532810-IND",
                        "532808-IND",
                        "532807-IND",
                        "532806-IND",
                        "532805-IND",
                        "532804-IND",
                        "532801-IND",
                        "532800-IND",
                        "532799-IND",
                        "532797-IND",
                        "532794-IND",
                        "532790-IND",
                        "532785-IND",
                        "532784-IND",
                        "532783-IND",
                        "532782-IND",
                        "532780-IND",
                        "532779-IND",
                        "532777-IND",
                        "532776-IND",
                        "532775-IND",
                        "532774-IND",
                        "532773-IND",
                        "532768-IND",
                        "532767-IND",
                        "532766-IND",
                        "532764-IND",
                        "532762-IND",
                        "532761-IND",
                        "532760-IND",
                        "532759-IND",
                        "532757-IND",
                        "532756-IND",
                        "532754-IND",
                        "532751-IND",
                        "532749-IND",
                        "532748-IND",
                        "532745-IND",
                        "532744-IND",
                        "532742-IND",
                        "532740-IND",
                        "532738-IND",
                        "532737-IND",
                        "532735-IND",
                        "532734-IND",
                        "532732-IND",
                        "532731-IND",
                        "532730-IND",
                        "532729-IND",
                        "532728-IND",
                        "532726-IND",
                        "532725-IND",
                        "532722-IND",
                        "532721-IND",
                        "532720-IND",
                        "532719-IND",
                        "532717-IND",
                        "532713-IND",
                        "532712-IND",
                        "532710-IND",
                        "532707-IND",
                        "532706-IND",
                        "532705-IND",
                        "532700-IND",
                        "532699-IND",
                        "532698-IND",
                        "532696-IND",
                        "532695-IND",
                        "532694-IND",
                        "532693-IND",
                        "532692-IND",
                        "532690-IND",
                        "532689-IND",
                        "532687-IND",
                        "532686-IND",
                        "532684-IND",
                        "532679-IND",
                        "532678-IND",
                        "532674-IND",
                        "532673-IND",
                        "532670-IND",
                        "532668-IND",
                        "532667-IND",
                        "532666-IND",
                        "532663-IND",
                        "532662-IND",
                        "532661-IND",
                        "532660-IND",
                        "532659-IND",
                        "532656-IND",
                        "532654-IND",
                        "532652-IND",
                        "532651-IND",
                        "532650-IND",
                        "532649-IND",
                        "532648-IND",
                        "532645-IND",
                        "532642-IND",
                        "532641-IND",
                        "532640-IND",
                        "532638-IND",
                        "532637-IND",
                        "532633-IND",
                        "532630-IND",
                        "532629-IND",
                        "532628-IND",
                        "532627-IND",
                        "532624-IND",
                        "532622-IND",
                        "532621-IND",
                        "532617-IND",
                        "532616-IND",
                        "532614-IND",
                        "532612-IND",
                        "532610-IND",
                        "532604-IND",
                        "532553-IND",
                        "532548-IND",
                        "532543-IND",
                        "532539-IND",
                        "532532-IND",
                        "532529-IND",
                        "532528-IND",
                        "532525-IND",
                        "532524-IND",
                        "532523-IND",
                        "532521-IND",
                        "532515-IND",
                        "532513-IND",
                        "532508-IND",
                        "532505-IND",
                        "532503-IND",
                        "532498-IND",
                        "532493-IND",
                        "532486-IND",
                        "532485-IND",
                        "532482-IND",
                        "532481-IND",
                        "532479-IND",
                        "532477-IND",
                        "532475-IND",
                        "532468-IND",
                        "532467-IND",
                        "532461-IND",
                        "532460-IND",
                        "532459-IND",
                        "532457-IND",
                        "532456-IND",
                        "532455-IND",
                        "532444-IND",
                        "532443-IND",
                        "532441-IND",
                        "532439-IND",
                        "532435-IND",
                        "532430-IND",
                        "532416-IND",
                        "532413-IND",
                        "532410-IND",
                        "532408-IND",
                        "532406-IND",
                        "532404-IND",
                        "532403-IND",
                        "532402-IND",
                        "532400-IND",
                        "532395-IND",
                        "532392-IND",
                        "532391-IND",
                        "532388-IND",
                        "532387-IND",
                        "532386-IND",
                        "532384-IND",
                        "532382-IND",
                        "532380-IND",
                        "532379-IND",
                        "532378-IND",
                        "532375-IND",
                        "532374-IND",
                        "532373-IND",
                        "532371-IND",
                        "532369-IND",
                        "532366-IND",
                        "532359-IND",
                        "532357-IND",
                        "532355-IND",
                        "532351-IND",
                        "532350-IND",
                        "532349-IND",
                        "532348-IND",
                        "532345-IND",
                        "532344-IND",
                        "532341-IND",
                        "532340-IND",
                        "532339-IND",
                        "532336-IND",
                        "532334-IND",
                        "532333-IND",
                        "532329-IND",
                        "532326-IND",
                        "532324-IND",
                        "532323-IND",
                        "532320-IND",
                        "532313-IND",
                        "532310-IND",
                        "532305-IND",
                        "532304-IND",
                        "532301-IND",
                        "532300-IND",
                        "532296-IND",
                        "532290-IND",
                        "532284-IND",
                        "532282-IND",
                        "532271-IND",
                        "532268-IND",
                        "532262-IND",
                        "532259-IND",
                        "532256-IND",
                        "532240-IND",
                        "532221-IND",
                        "532219-IND",
                        "532218-IND",
                        "532217-IND",
                        "532216-IND",
                        "532212-IND",
                        "532209-IND",
                        "532183-IND",
                        "532181-IND",
                        "532180-IND",
                        "532175-IND",
                        "532173-IND",
                        "532167-IND",
                        "532164-IND",
                        "532163-IND",
                        "532162-IND",
                        "532160-IND",
                        "532156-IND",
                        "532154-IND",
                        "532150-IND",
                        "532149-IND",
                        "532145-IND",
                        "532144-IND",
                        "532141-IND",
                        "532140-IND",
                        "532124-IND",
                        "532123-IND",
                        "532105-IND",
                        "532102-IND",
                        "532100-IND",
                        "532097-IND",
                        "532092-IND",
                        "532090-IND",
                        "532083-IND",
                        "532070-IND",
                        "532067-IND",
                        "532057-IND",
                        "532056-IND",
                        "532054-IND",
                        "532053-IND",
                        "532051-IND",
                        "532041-IND",
                        "532039-IND",
                        "532035-IND",
                        "532029-IND",
                        "532024-IND",
                        "532022-IND",
                        "532016-IND",
                        "532015-IND",
                        "532011-IND",
                        "532005-IND",
                        "532001-IND",
                        "531996-IND",
                        "531991-IND",
                        "531989-IND",
                        "531982-IND",
                        "531980-IND",
                        "531979-IND",
                        "531978-IND",
                        "531977-IND",
                        "531971-IND",
                        "531968-IND",
                        "531962-IND",
                        "531952-IND",
                        "531950-IND",
                        "531936-IND",
                        "531931-IND",
                        "531930-IND",
                        "531929-IND",
                        "531928-IND",
                        "531923-IND",
                        "531921-IND",
                        "531918-IND",
                        "531917-IND",
                        "531911-IND",
                        "531900-IND",
                        "531893-IND",
                        "531892-IND",
                        "531889-IND",
                        "531887-IND",
                        "531885-IND",
                        "531879-IND",
                        "531878-IND",
                        "531870-IND",
                        "531869-IND",
                        "531867-IND",
                        "531861-IND",
                        "531859-IND",
                        "531847-IND",
                        "531846-IND",
                        "531842-IND",
                        "531832-IND",
                        "531822-IND",
                        "531821-IND",
                        "531819-IND",
                        "531814-IND",
                        "531813-IND",
                        "531812-IND",
                        "531810-IND",
                        "531802-IND",
                        "531797-IND",
                        "531795-IND",
                        "531784-IND",
                        "531780-IND",
                        "531778-IND",
                        "531771-IND",
                        "531762-IND",
                        "531761-IND",
                        "531758-IND",
                        "531752-IND",
                        "531746-IND",
                        "531744-IND",
                        "531743-IND",
                        "531739-IND",
                        "531737-IND",
                        "531735-IND",
                        "531727-IND",
                        "531726-IND",
                        "531719-IND",
                        "531716-IND",
                        "531694-IND",
                        "531686-IND",
                        "531681-IND",
                        "531680-IND",
                        "531677-IND",
                        "531676-IND",
                        "531673-IND",
                        "531672-IND",
                        "531661-IND",
                        "531658-IND",
                        "531652-IND",
                        "531651-IND",
                        "531648-IND",
                        "531644-IND",
                        "531640-IND",
                        "531638-IND",
                        "531635-IND",
                        "531633-IND",
                        "531626-IND",
                        "531624-IND",
                        "531621-IND",
                        "531616-IND",
                        "531609-IND",
                        "531608-IND",
                        "531600-IND",
                        "531599-IND",
                        "531595-IND",
                        "531594-IND",
                        "531591-IND",
                        "531585-IND",
                        "531583-IND",
                        "531582-IND",
                        "531574-IND",
                        "531568-IND",
                        "531560-IND",
                        "531556-IND",
                        "531552-IND",
                        "531548-IND",
                        "531543-IND",
                        "531540-IND",
                        "531539-IND",
                        "531533-IND",
                        "531531-IND",
                        "531525-IND",
                        "531521-IND",
                        "531515-IND",
                        "531509-IND",
                        "531508-IND",
                        "531506-IND",
                        "531505-IND",
                        "531502-IND",
                        "531500-IND",
                        "531499-IND",
                        "531497-IND",
                        "531496-IND",
                        "531495-IND",
                        "531494-IND",
                        "531472-IND",
                        "531471-IND",
                        "531465-IND",
                        "531460-IND",
                        "531456-IND",
                        "531454-IND",
                        "531453-IND",
                        "531449-IND",
                        "531444-IND",
                        "531439-IND",
                        "531436-IND",
                        "531433-IND",
                        "531431-IND",
                        "531417-IND",
                        "531416-IND",
                        "531413-IND",
                        "531412-IND",
                        "531411-IND",
                        "531409-IND",
                        "531406-IND",
                        "531402-IND",
                        "531400-IND",
                        "531397-IND",
                        "531396-IND",
                        "531395-IND",
                        "531387-IND",
                        "531380-IND",
                        "531373-IND",
                        "531370-IND",
                        "531359-IND",
                        "531358-IND",
                        "531352-IND",
                        "531349-IND",
                        "531346-IND",
                        "531343-IND",
                        "531341-IND",
                        "531338-IND",
                        "531336-IND",
                        "531335-IND",
                        "531327-IND",
                        "531319-IND",
                        "531314-IND",
                        "531310-IND",
                        "531306-IND",
                        "531304-IND",
                        "531301-IND",
                        "531300-IND",
                        "531297-IND",
                        "531289-IND",
                        "531288-IND",
                        "531287-IND",
                        "531283-IND",
                        "531281-IND",
                        "531280-IND",
                        "531278-IND",
                        "531274-IND",
                        "531272-IND",
                        "531268-IND",
                        "531266-IND",
                        "531265-IND",
                        "531259-IND",
                        "531257-IND",
                        "531255-IND",
                        "531246-IND",
                        "531237-IND",
                        "531235-IND",
                        "531233-IND",
                        "531228-IND",
                        "531227-IND",
                        "531225-IND",
                        "531223-IND",
                        "531221-IND",
                        "531216-IND",
                        "531215-IND",
                        "531213-IND",
                        "531212-IND",
                        "531211-IND",
                        "531210-IND",
                        "531205-IND",
                        "531203-IND",
                        "531201-IND",
                        "531199-IND",
                        "531198-IND",
                        "531196-IND",
                        "531190-IND",
                        "531179-IND",
                        "531178-IND",
                        "531176-IND",
                        "531173-IND",
                        "531172-IND",
                        "531169-IND",
                        "531164-IND",
                        "531161-IND",
                        "531157-IND",
                        "531156-IND",
                        "531155-IND",
                        "531153-IND",
                        "531147-IND",
                        "531146-IND",
                        "531137-IND",
                        "531126-IND",
                        "531120-IND",
                        "531119-IND",
                        "531111-IND",
                        "531091-IND",
                        "531083-IND",
                        "531082-IND",
                        "531080-IND",
                        "531069-IND",
                        "531067-IND",
                        "531065-IND",
                        "531051-IND",
                        "531049-IND",
                        "531043-IND",
                        "531041-IND",
                        "531033-IND",
                        "531027-IND",
                        "531025-IND",
                        "531017-IND",
                        "531003-IND",
                        "530999-IND",
                        "530997-IND",
                        "530993-IND",
                        "530991-IND",
                        "530985-IND",
                        "530979-IND",
                        "530973-IND",
                        "530965-IND",
                        "530959-IND",
                        "530953-IND",
                        "530951-IND",
                        "530931-IND",
                        "530925-IND",
                        "530919-IND",
                        "530917-IND",
                        "530915-IND",
                        "530909-IND",
                        "530905-IND",
                        "530899-IND",
                        "530897-IND",
                        "530883-IND",
                        "530871-IND",
                        "530853-IND",
                        "530845-IND",
                        "530843-IND",
                        "530839-IND",
                        "530829-IND",
                        "530825-IND",
                        "530821-IND",
                        "530813-IND",
                        "530809-IND",
                        "530803-IND",
                        "530799-IND",
                        "530797-IND",
                        "530795-IND",
                        "530789-IND",
                        "530783-IND",
                        "530771-IND",
                        "530765-IND",
                        "530747-IND",
                        "530735-IND",
                        "530733-IND",
                        "530723-IND",
                        "530715-IND",
                        "530713-IND",
                        "530711-IND",
                        "530705-IND",
                        "530699-IND",
                        "530695-IND",
                        "530689-IND",
                        "530683-IND",
                        "530675-IND",
                        "530669-IND",
                        "530665-IND",
                        "530643-IND",
                        "530617-IND",
                        "530615-IND",
                        "530611-IND",
                        "530609-IND",
                        "530589-IND",
                        "530585-IND",
                        "530577-IND",
                        "530571-IND",
                        "530565-IND",
                        "530555-IND",
                        "530549-IND",
                        "530545-IND",
                        "530543-IND",
                        "530537-IND",
                        "530533-IND",
                        "530525-IND",
                        "530521-IND",
                        "530499-IND",
                        "530477-IND",
                        "530475-IND",
                        "530469-IND",
                        "530461-IND",
                        "530459-IND",
                        "530457-IND",
                        "530449-IND",
                        "530443-IND",
                        "530439-IND",
                        "530433-IND",
                        "530431-IND",
                        "530429-IND",
                        "530427-IND",
                        "530421-IND",
                        "530419-IND",
                        "530407-IND",
                        "530405-IND",
                        "530401-IND",
                        "530393-IND",
                        "530377-IND",
                        "530369-IND",
                        "530365-IND",
                        "530363-IND",
                        "530361-IND",
                        "530357-IND",
                        "530343-IND",
                        "530341-IND",
                        "530331-IND",
                        "530317-IND",
                        "530315-IND",
                        "530309-IND",
                        "530307-IND",
                        "530305-IND",
                        "530299-IND",
                        "530289-IND",
                        "530281-IND",
                        "530271-IND",
                        "530267-IND",
                        "530263-IND",
                        "530259-IND",
                        "530255-IND",
                        "530253-IND",
                        "530249-IND",
                        "530245-IND",
                        "530239-IND",
                        "530235-IND",
                        "530233-IND",
                        "530231-IND",
                        "530219-IND",
                        "530215-IND",
                        "530213-IND",
                        "530207-IND",
                        "530199-IND",
                        "530187-IND",
                        "530185-IND",
                        "530179-IND",
                        "530177-IND",
                        "530175-IND",
                        "530173-IND",
                        "530171-IND",
                        "530169-IND",
                        "530167-IND",
                        "530163-IND",
                        "530161-IND",
                        "530151-IND",
                        "530145-IND",
                        "530139-IND",
                        "530135-IND",
                        "530133-IND",
                        "530131-IND",
                        "530129-IND",
                        "530127-IND",
                        "530125-IND",
                        "530119-IND",
                        "530117-IND",
                        "530111-IND",
                        "530079-IND",
                        "530077-IND",
                        "530075-IND",
                        "530073-IND",
                        "530065-IND",
                        "530063-IND",
                        "530055-IND",
                        "530045-IND",
                        "530043-IND",
                        "530037-IND",
                        "530035-IND",
                        "530025-IND",
                        "530023-IND",
                        "530019-IND",
                        "530017-IND",
                        "530011-IND",
                        "530007-IND",
                        "530005-IND",
                        "530001-IND",
                        "527005-IND",
                        "527001-IND",
                        "526987-IND",
                        "526983-IND",
                        "526977-IND",
                        "526971-IND",
                        "526967-IND",
                        "526965-IND",
                        "526959-IND",
                        "526953-IND",
                        "526951-IND",
                        "526947-IND",
                        "526945-IND",
                        "526931-IND",
                        "526921-IND",
                        "526901-IND",
                        "526899-IND",
                        "526891-IND",
                        "526887-IND",
                        "526885-IND",
                        "526881-IND",
                        "526871-IND",
                        "526865-IND",
                        "526861-IND",
                        "526859-IND",
                        "526853-IND",
                        "526851-IND",
                        "526849-IND",
                        "526839-IND",
                        "526829-IND",
                        "526827-IND",
                        "526823-IND",
                        "526821-IND",
                        "526817-IND",
                        "526813-IND",
                        "526807-IND",
                        "526799-IND",
                        "526797-IND",
                        "526795-IND",
                        "526783-IND",
                        "526775-IND",
                        "526761-IND",
                        "526755-IND",
                        "526751-IND",
                        "526747-IND",
                        "526739-IND",
                        "526731-IND",
                        "526729-IND",
                        "526727-IND",
                        "526725-IND",
                        "526723-IND",
                        "526717-IND",
                        "526711-IND",
                        "526703-IND",
                        "526687-IND",
                        "526683-IND",
                        "526677-IND",
                        "526668-IND",
                        "526666-IND",
                        "526662-IND",
                        "526654-IND",
                        "526650-IND",
                        "526642-IND",
                        "526622-IND",
                        "526616-IND",
                        "526614-IND",
                        "526612-IND",
                        "526608-IND",
                        "526604-IND",
                        "526596-IND",
                        "526588-IND",
                        "526586-IND",
                        "526582-IND",
                        "526576-IND",
                        "526574-IND",
                        "526570-IND",
                        "526568-IND",
                        "526554-IND",
                        "526546-IND",
                        "526544-IND",
                        "526532-IND",
                        "526521-IND",
                        "526519-IND",
                        "526506-IND",
                        "526504-IND",
                        "526500-IND",
                        "526492-IND",
                        "526481-IND",
                        "526479-IND",
                        "526477-IND",
                        "526471-IND",
                        "526445-IND",
                        "526443-IND",
                        "526441-IND",
                        "526435-IND",
                        "526433-IND",
                        "526431-IND",
                        "526423-IND",
                        "526415-IND",
                        "526409-IND",
                        "526397-IND",
                        "526381-IND",
                        "526373-IND",
                        "526367-IND",
                        "526365-IND",
                        "526355-IND",
                        "526349-IND",
                        "526335-IND",
                        "526325-IND",
                        "526315-IND",
                        "526301-IND",
                        "526269-IND",
                        "526263-IND",
                        "526251-IND",
                        "526247-IND",
                        "526241-IND",
                        "526237-IND",
                        "526235-IND",
                        "526227-IND",
                        "526225-IND",
                        "526217-IND",
                        "526211-IND",
                        "526195-IND",
                        "526193-IND",
                        "526187-IND",
                        "526179-IND",
                        "526169-IND",
                        "526161-IND",
                        "526159-IND",
                        "526143-IND",
                        "526139-IND",
                        "526137-IND",
                        "526133-IND",
                        "526125-IND",
                        "526117-IND",
                        "526115-IND",
                        "526113-IND",
                        "526073-IND",
                        "526043-IND",
                        "526027-IND",
                        "526001-IND",
                        "524828-IND",
                        "524824-IND",
                        "524820-IND",
                        "524818-IND",
                        "524816-IND",
                        "524808-IND",
                        "524804-IND",
                        "524790-IND",
                        "524774-IND",
                        "524748-IND",
                        "524743-IND",
                        "524742-IND",
                        "524735-IND",
                        "524731-IND",
                        "524723-IND",
                        "524717-IND",
                        "524703-IND",
                        "524687-IND",
                        "524669-IND",
                        "524667-IND",
                        "524663-IND",
                        "524654-IND",
                        "524648-IND",
                        "524642-IND",
                        "524640-IND",
                        "524634-IND",
                        "524628-IND",
                        "524624-IND",
                        "524622-IND",
                        "524606-IND",
                        "524598-IND",
                        "524594-IND",
                        "524592-IND",
                        "524590-IND",
                        "524580-IND",
                        "524576-IND",
                        "524572-IND",
                        "524570-IND",
                        "524564-IND",
                        "524558-IND",
                        "524522-IND",
                        "524520-IND",
                        "524518-IND",
                        "524516-IND",
                        "524514-IND",
                        "524506-IND",
                        "524500-IND",
                        "524480-IND",
                        "524470-IND",
                        "524458-IND",
                        "524440-IND",
                        "524408-IND",
                        "524404-IND",
                        "524394-IND",
                        "524372-IND",
                        "524370-IND",
                        "524348-IND",
                        "524342-IND",
                        "524336-IND",
                        "524330-IND",
                        "524322-IND",
                        "524314-IND",
                        "524288-IND",
                        "524280-IND",
                        "524230-IND",
                        "524226-IND",
                        "524218-IND",
                        "524212-IND",
                        "524210-IND",
                        "524202-IND",
                        "524174-IND",
                        "524156-IND",
                        "524136-IND",
                        "524129-IND",
                        "524109-IND",
                        "524091-IND",
                        "524080-IND",
                        "524075-IND",
                        "524051-IND",
                        "524038-IND",
                        "524037-IND",
                        "524031-IND",
                        "524019-IND",
                        "524013-IND",
                        "524000-IND",
                        "523888-IND",
                        "523874-IND",
                        "523850-IND",
                        "523842-IND",
                        "523840-IND",
                        "523838-IND",
                        "523836-IND",
                        "523828-IND",
                        "523826-IND",
                        "523796-IND",
                        "523792-IND",
                        "523790-IND",
                        "523782-IND",
                        "523756-IND",
                        "523754-IND",
                        "523752-IND",
                        "523732-IND",
                        "523722-IND",
                        "523716-IND",
                        "523712-IND",
                        "523710-IND",
                        "523708-IND",
                        "523704-IND",
                        "523696-IND",
                        "523694-IND",
                        "523672-IND",
                        "523660-IND",
                        "523652-IND",
                        "523650-IND",
                        "523648-IND",
                        "523638-IND",
                        "523630-IND",
                        "523620-IND",
                        "523618-IND",
                        "523610-IND",
                        "523606-IND",
                        "523598-IND",
                        "523594-IND",
                        "523574-IND",
                        "523566-IND",
                        "523550-IND",
                        "523539-IND",
                        "523537-IND",
                        "523519-IND",
                        "523489-IND",
                        "523483-IND",
                        "523475-IND",
                        "523465-IND",
                        "523457-IND",
                        "523449-IND",
                        "523445-IND",
                        "523425-IND",
                        "523419-IND",
                        "523411-IND",
                        "523405-IND",
                        "523398-IND",
                        "523395-IND",
                        "523391-IND",
                        "523385-IND",
                        "523384-IND",
                        "523373-IND",
                        "523371-IND",
                        "523369-IND",
                        "523367-IND",
                        "523351-IND",
                        "523343-IND",
                        "523315-IND",
                        "523301-IND",
                        "523289-IND",
                        "523283-IND",
                        "523277-IND",
                        "523261-IND",
                        "523260-IND",
                        "523248-IND",
                        "523242-IND",
                        "523232-IND",
                        "523229-IND",
                        "523222-IND",
                        "523207-IND",
                        "523204-IND",
                        "523164-IND",
                        "523160-IND",
                        "523151-IND",
                        "523144-IND",
                        "523127-IND",
                        "523120-IND",
                        "523116-IND",
                        "523113-IND",
                        "523105-IND",
                        "523100-IND",
                        "523062-IND",
                        "523054-IND",
                        "523025-IND",
                        "523023-IND",
                        "523021-IND",
                        "523019-IND",
                        "523011-IND",
                        "523007-IND",
                        "522650-IND",
                        "522295-IND",
                        "522292-IND",
                        "522285-IND",
                        "522281-IND",
                        "522275-IND",
                        "522273-IND",
                        "522267-IND",
                        "522263-IND",
                        "522257-IND",
                        "522251-IND",
                        "522249-IND",
                        "522245-IND",
                        "522241-IND",
                        "522237-IND",
                        "522231-IND",
                        "522217-IND",
                        "522215-IND",
                        "522209-IND",
                        "522207-IND",
                        "522205-IND",
                        "522195-IND",
                        "522183-IND",
                        "522165-IND",
                        "522163-IND",
                        "522152-IND",
                        "522134-IND",
                        "522122-IND",
                        "522113-IND",
                        "522108-IND",
                        "522105-IND",
                        "522101-IND",
                        "522074-IND",
                        "522064-IND",
                        "522036-IND",
                        "522034-IND",
                        "522029-IND",
                        "522027-IND",
                        "522017-IND",
                        "522014-IND",
                        "522005-IND",
                        "522004-IND",
                        "522001-IND",
                        "521248-IND",
                        "521242-IND",
                        "521240-IND",
                        "521234-IND",
                        "521232-IND",
                        "521228-IND",
                        "521226-IND",
                        "521222-IND",
                        "521220-IND",
                        "521216-IND",
                        "521210-IND",
                        "521206-IND",
                        "521200-IND",
                        "521194-IND",
                        "521188-IND",
                        "521182-IND",
                        "521180-IND",
                        "521178-IND",
                        "521163-IND",
                        "521161-IND",
                        "521151-IND",
                        "521149-IND",
                        "521141-IND",
                        "521137-IND",
                        "521133-IND",
                        "521131-IND",
                        "521113-IND",
                        "521109-IND",
                        "521105-IND",
                        "521097-IND",
                        "521082-IND",
                        "521080-IND",
                        "521070-IND",
                        "521068-IND",
                        "521064-IND",
                        "521062-IND",
                        "521054-IND",
                        "521036-IND",
                        "521034-IND",
                        "521018-IND",
                        "521016-IND",
                        "521003-IND",
                        "520155-IND",
                        "520141-IND",
                        "520131-IND",
                        "520127-IND",
                        "520123-IND",
                        "520121-IND",
                        "520113-IND",
                        "520086-IND",
                        "520081-IND",
                        "520075-IND",
                        "520073-IND",
                        "520066-IND",
                        "520059-IND",
                        "520056-IND",
                        "520043-IND",
                        "520021-IND",
                        "520008-IND",
                        "519612-IND",
                        "519606-IND",
                        "519602-IND",
                        "519600-IND",
                        "519588-IND",
                        "519566-IND",
                        "519552-IND",
                        "519483-IND",
                        "519475-IND",
                        "519457-IND",
                        "519455-IND",
                        "519439-IND",
                        "519421-IND",
                        "519415-IND",
                        "519383-IND",
                        "519367-IND",
                        "519331-IND",
                        "519307-IND",
                        "519299-IND",
                        "519295-IND",
                        "519287-IND",
                        "519285-IND",
                        "519262-IND",
                        "519242-IND",
                        "519234-IND",
                        "519230-IND",
                        "519216-IND",
                        "519214-IND",
                        "519183-IND",
                        "519174-IND",
                        "519156-IND",
                        "519152-IND",
                        "519136-IND",
                        "519126-IND",
                        "519105-IND",
                        "519097-IND",
                        "519091-IND",
                        "519064-IND",
                        "519031-IND",
                        "519014-IND",
                        "519003-IND",
                        "518091-IND",
                        "518075-IND",
                        "518029-IND",
                        "518011-IND",
                        "517571-IND",
                        "517562-IND",
                        "517554-IND",
                        "517548-IND",
                        "517546-IND",
                        "517544-IND",
                        "517536-IND",
                        "517530-IND",
                        "517522-IND",
                        "517506-IND",
                        "517500-IND",
                        "517498-IND",
                        "517477-IND",
                        "517463-IND",
                        "517449-IND",
                        "517447-IND",
                        "517437-IND",
                        "517421-IND",
                        "517417-IND",
                        "517399-IND",
                        "517397-IND",
                        "517393-IND",
                        "517385-IND",
                        "517380-IND",
                        "517372-IND",
                        "517370-IND",
                        "517360-IND",
                        "517356-IND",
                        "517344-IND",
                        "517334-IND",
                        "517330-IND",
                        "517320-IND",
                        "517300-IND",
                        "517288-IND",
                        "517271-IND",
                        "517264-IND",
                        "517258-IND",
                        "517246-IND",
                        "517238-IND",
                        "517236-IND",
                        "517230-IND",
                        "517201-IND",
                        "517170-IND",
                        "517166-IND",
                        "517146-IND",
                        "517119-IND",
                        "517096-IND",
                        "517077-IND",
                        "517063-IND",
                        "517059-IND",
                        "517044-IND",
                        "517041-IND",
                        "517035-IND",
                        "517015-IND",
                        "516110-IND",
                        "516108-IND",
                        "516106-IND",
                        "516098-IND",
                        "516096-IND",
                        "516082-IND",
                        "516078-IND",
                        "516072-IND",
                        "516032-IND",
                        "516022-IND",
                        "516020-IND",
                        "516016-IND",
                        "516007-IND",
                        "515147-IND",
                        "515085-IND",
                        "515059-IND",
                        "515055-IND",
                        "515043-IND",
                        "515037-IND",
                        "514484-IND",
                        "514482-IND",
                        "514470-IND",
                        "514460-IND",
                        "514454-IND",
                        "514450-IND",
                        "514442-IND",
                        "514440-IND",
                        "514428-IND",
                        "514418-IND",
                        "514412-IND",
                        "514400-IND",
                        "514394-IND",
                        "514386-IND",
                        "514378-IND",
                        "514358-IND",
                        "514354-IND",
                        "514332-IND",
                        "514326-IND",
                        "514324-IND",
                        "514322-IND",
                        "514318-IND",
                        "514316-IND",
                        "514312-IND",
                        "514302-IND",
                        "514300-IND",
                        "514286-IND",
                        "514272-IND",
                        "514266-IND",
                        "514264-IND",
                        "514260-IND",
                        "514236-IND",
                        "514234-IND",
                        "514215-IND",
                        "514211-IND",
                        "514197-IND",
                        "514183-IND",
                        "514175-IND",
                        "514171-IND",
                        "514167-IND",
                        "514165-IND",
                        "514162-IND",
                        "514144-IND",
                        "514142-IND",
                        "514140-IND",
                        "514138-IND",
                        "514128-IND",
                        "514113-IND",
                        "514087-IND",
                        "514060-IND",
                        "514045-IND",
                        "514043-IND",
                        "514036-IND",
                        "514034-IND",
                        "514030-IND",
                        "514010-IND",
                        "513729-IND",
                        "513723-IND",
                        "513721-IND",
                        "513709-IND",
                        "513699-IND",
                        "513693-IND",
                        "513691-IND",
                        "513687-IND",
                        "513683-IND",
                        "513642-IND",
                        "513605-IND",
                        "513599-IND",
                        "513579-IND",
                        "513558-IND",
                        "513554-IND",
                        "513548-IND",
                        "513540-IND",
                        "513536-IND",
                        "513532-IND",
                        "513528-IND",
                        "513517-IND",
                        "513515-IND",
                        "513513-IND",
                        "513511-IND",
                        "513509-IND",
                        "513507-IND",
                        "513502-IND",
                        "513488-IND",
                        "513472-IND",
                        "513460-IND",
                        "513456-IND",
                        "513452-IND",
                        "513446-IND",
                        "513436-IND",
                        "513434-IND",
                        "513422-IND",
                        "513418-IND",
                        "513401-IND",
                        "513397-IND",
                        "513377-IND",
                        "513375-IND",
                        "513369-IND",
                        "513361-IND",
                        "513349-IND",
                        "513337-IND",
                        "513335-IND",
                        "513309-IND",
                        "513307-IND",
                        "513303-IND",
                        "513269-IND",
                        "513262-IND",
                        "513252-IND",
                        "513250-IND",
                        "513228-IND",
                        "513216-IND",
                        "513173-IND",
                        "513149-IND",
                        "513121-IND",
                        "513119-IND",
                        "513117-IND",
                        "513108-IND",
                        "513097-IND",
                        "513063-IND",
                        "513059-IND",
                        "513043-IND",
                        "513039-IND",
                        "513023-IND",
                        "513005-IND",
                        "512634-IND",
                        "512626-IND",
                        "512624-IND",
                        "512618-IND",
                        "512608-IND",
                        "512604-IND",
                        "512600-IND",
                        "512599-IND",
                        "512595-IND",
                        "512591-IND",
                        "512589-IND",
                        "512587-IND",
                        "512573-IND",
                        "512565-IND",
                        "512553-IND",
                        "512531-IND",
                        "512529-IND",
                        "512527-IND",
                        "512519-IND",
                        "512511-IND",
                        "512499-IND",
                        "512493-IND",
                        "512489-IND",
                        "512485-IND",
                        "512481-IND",
                        "512479-IND",
                        "512477-IND",
                        "512463-IND",
                        "512461-IND",
                        "512455-IND",
                        "512453-IND",
                        "512445-IND",
                        "512437-IND",
                        "512425-IND",
                        "512415-IND",
                        "512408-IND",
                        "512399-IND",
                        "512393-IND",
                        "512379-IND",
                        "512359-IND",
                        "512345-IND",
                        "512341-IND",
                        "512337-IND",
                        "512329-IND",
                        "512296-IND",
                        "512291-IND",
                        "512279-IND",
                        "512277-IND",
                        "512271-IND",
                        "512267-IND",
                        "512261-IND",
                        "512257-IND",
                        "512247-IND",
                        "512245-IND",
                        "512237-IND",
                        "512229-IND",
                        "512217-IND",
                        "512213-IND",
                        "512195-IND",
                        "512179-IND",
                        "512175-IND",
                        "512169-IND",
                        "512157-IND",
                        "512131-IND",
                        "512109-IND",
                        "512101-IND",
                        "512099-IND",
                        "512093-IND",
                        "512091-IND",
                        "512068-IND",
                        "512065-IND",
                        "512063-IND",
                        "512062-IND",
                        "512047-IND",
                        "512026-IND",
                        "512025-IND",
                        "512024-IND",
                        "512022-IND",
                        "512020-IND",
                        "512018-IND",
                        "512014-IND",
                        "511768-IND",
                        "511766-IND",
                        "511764-IND",
                        "511760-IND",
                        "511758-IND",
                        "511756-IND",
                        "511754-IND",
                        "511738-IND",
                        "511736-IND",
                        "511730-IND",
                        "511726-IND",
                        "511724-IND",
                        "511716-IND",
                        "511714-IND",
                        "511712-IND",
                        "511710-IND",
                        "511702-IND",
                        "511700-IND",
                        "511692-IND",
                        "511688-IND",
                        "511676-IND",
                        "511672-IND",
                        "511658-IND",
                        "511634-IND",
                        "511630-IND",
                        "511628-IND",
                        "511618-IND",
                        "511611-IND",
                        "511609-IND",
                        "511605-IND",
                        "511593-IND",
                        "511585-IND",
                        "511577-IND",
                        "511571-IND",
                        "511559-IND",
                        "511557-IND",
                        "511551-IND",
                        "511549-IND",
                        "511543-IND",
                        "511539-IND",
                        "511535-IND",
                        "511533-IND",
                        "511525-IND",
                        "511523-IND",
                        "511509-IND",
                        "511507-IND",
                        "511505-IND",
                        "511493-IND",
                        "511473-IND",
                        "511451-IND",
                        "511431-IND",
                        "511413-IND",
                        "511411-IND",
                        "511391-IND",
                        "511389-IND",
                        "511377-IND",
                        "511367-IND",
                        "511359-IND",
                        "511355-IND",
                        "511333-IND",
                        "511260-IND",
                        "511254-IND",
                        "511243-IND",
                        "511218-IND",
                        "511200-IND",
                        "511196-IND",
                        "511185-IND",
                        "511176-IND",
                        "511149-IND",
                        "511147-IND",
                        "511139-IND",
                        "511131-IND",
                        "511122-IND",
                        "511116-IND",
                        "511110-IND",
                        "511108-IND",
                        "511096-IND",
                        "511076-IND",
                        "511074-IND",
                        "511072-IND",
                        "511066-IND",
                        "511060-IND",
                        "511048-IND",
                        "511012-IND",
                        "511000-IND",
                        "510245-IND",
                        "509966-IND",
                        "509953-IND",
                        "509917-IND",
                        "509910-IND",
                        "509895-IND",
                        "509887-IND",
                        "509874-IND",
                        "509870-IND",
                        "509835-IND",
                        "509820-IND",
                        "509782-IND",
                        "509709-IND",
                        "509692-IND",
                        "509675-IND",
                        "509650-IND",
                        "509635-IND",
                        "509631-IND",
                        "509597-IND",
                        "509567-IND",
                        "509563-IND",
                        "509546-IND",
                        "509525-IND",
                        "509496-IND",
                        "509488-IND",
                        "509486-IND",
                        "509472-IND",
                        "509449-IND",
                        "509438-IND",
                        "509423-IND",
                        "509243-IND",
                        "509220-IND",
                        "509196-IND",
                        "509162-IND",
                        "509152-IND",
                        "509099-IND",
                        "509084-IND",
                        "509079-IND",
                        "509077-IND",
                        "509073-IND",
                        "509069-IND",
                        "509055-IND",
                        "509053-IND",
                        "509048-IND",
                        "509046-IND",
                        "509040-IND",
                        "509026-IND",
                        "509020-IND",
                        "509015-IND",
                        "509009-IND",
                        "509003-IND",
                        "508996-IND",
                        "508993-IND",
                        "508989-IND",
                        "508980-IND",
                        "508961-IND",
                        "508954-IND",
                        "508941-IND",
                        "508933-IND",
                        "508922-IND",
                        "508906-IND",
                        "508905-IND",
                        "508869-IND",
                        "508867-IND",
                        "508814-IND",
                        "508807-IND",
                        "508670-IND",
                        "508664-IND",
                        "508494-IND",
                        "508486-IND",
                        "508306-IND",
                        "507998-IND",
                        "507981-IND",
                        "507970-IND",
                        "507962-IND",
                        "507960-IND",
                        "507948-IND",
                        "507946-IND",
                        "507944-IND",
                        "507938-IND",
                        "507917-IND",
                        "507912-IND",
                        "507910-IND",
                        "507894-IND",
                        "507886-IND",
                        "507880-IND",
                        "507878-IND",
                        "507872-IND",
                        "507864-IND",
                        "507852-IND",
                        "507836-IND",
                        "507828-IND",
                        "507815-IND",
                        "507813-IND",
                        "507794-IND",
                        "507789-IND",
                        "507785-IND",
                        "507779-IND",
                        "507759-IND",
                        "507747-IND",
                        "507690-IND",
                        "507663-IND",
                        "507645-IND",
                        "507621-IND",
                        "507609-IND",
                        "507580-IND",
                        "507525-IND",
                        "507515-IND",
                        "507508-IND",
                        "507490-IND",
                        "507488-IND",
                        "507486-IND",
                        "507438-IND",
                        "507410-IND",
                        "507315-IND",
                        "507300-IND",
                        "507205-IND",
                        "507180-IND",
                        "507155-IND",
                        "506981-IND",
                        "506975-IND",
                        "506947-IND",
                        "506919-IND",
                        "506910-IND",
                        "506879-IND",
                        "506867-IND",
                        "506863-IND",
                        "506858-IND",
                        "506854-IND",
                        "506852-IND",
                        "506820-IND",
                        "506767-IND",
                        "506734-IND",
                        "506690-IND",
                        "506687-IND",
                        "506685-IND",
                        "506642-IND",
                        "506618-IND",
                        "506605-IND",
                        "506597-IND",
                        "506590-IND",
                        "506579-IND",
                        "506543-IND",
                        "506532-IND",
                        "506530-IND",
                        "506528-IND",
                        "506522-IND",
                        "506520-IND",
                        "506405-IND",
                        "506401-IND",
                        "506395-IND",
                        "506390-IND",
                        "506365-IND",
                        "506313-IND",
                        "506285-IND",
                        "506261-IND",
                        "506260-IND",
                        "506235-IND",
                        "506222-IND",
                        "506197-IND",
                        "506196-IND",
                        "506194-IND",
                        "506190-IND",
                        "506184-IND",
                        "506178-IND",
                        "506166-IND",
                        "506162-IND",
                        "506146-IND",
                        "506134-IND",
                        "506128-IND",
                        "506109-IND",
                        "506105-IND",
                        "506087-IND",
                        "506076-IND",
                        "506074-IND",
                        "506022-IND",
                        "506003-IND",
                        "505978-IND",
                        "505890-IND",
                        "505872-IND",
                        "505854-IND",
                        "505850-IND",
                        "505840-IND",
                        "505827-IND",
                        "505807-IND",
                        "505800-IND",
                        "505750-IND",
                        "505744-IND",
                        "505737-IND",
                        "505729-IND",
                        "505726-IND",
                        "505725-IND",
                        "505720-IND",
                        "505714-IND",
                        "505711-IND",
                        "505703-IND",
                        "505700-IND",
                        "505688-IND",
                        "505685-IND",
                        "505681-IND",
                        "505650-IND",
                        "505594-IND",
                        "505590-IND",
                        "505576-IND",
                        "505533-IND",
                        "505526-IND",
                        "505523-IND",
                        "505515-IND",
                        "505509-IND",
                        "505412-IND",
                        "505400-IND",
                        "505368-IND",
                        "505358-IND",
                        "505355-IND",
                        "505336-IND",
                        "505324-IND",
                        "505302-IND",
                        "505285-IND",
                        "505283-IND",
                        "505255-IND",
                        "505250-IND",
                        "505242-IND",
                        "505232-IND",
                        "505216-IND",
                        "505212-IND",
                        "505196-IND",
                        "505163-IND",
                        "505160-IND",
                        "505141-IND",
                        "505075-IND",
                        "505036-IND",
                        "505010-IND",
                        "504988-IND",
                        "504973-IND",
                        "504966-IND",
                        "504961-IND",
                        "504959-IND",
                        "504908-IND",
                        "504882-IND",
                        "504879-IND",
                        "504840-IND",
                        "504810-IND",
                        "504746-IND",
                        "504741-IND",
                        "504731-IND",
                        "504717-IND",
                        "504673-IND",
                        "504646-IND",
                        "504614-IND",
                        "504605-IND",
                        "504398-IND",
                        "504397-IND",
                        "504392-IND",
                        "504378-IND",
                        "504375-IND",
                        "504365-IND",
                        "504340-IND",
                        "504273-IND",
                        "504258-IND",
                        "504240-IND",
                        "504220-IND",
                        "504212-IND",
                        "504180-IND",
                        "504176-IND",
                        "504132-IND",
                        "504112-IND",
                        "504093-IND",
                        "504092-IND",
                        "504084-IND",
                        "504080-IND",
                        "504076-IND",
                        "504067-IND",
                        "504058-IND",
                        "504036-IND",
                        "504028-IND",
                        "504008-IND",
                        "504000-IND",
                        "503960-IND",
                        "503893-IND",
                        "503863-IND",
                        "503837-IND",
                        "503816-IND",
                        "503811-IND",
                        "503804-IND",
                        "503776-IND",
                        "503772-IND",
                        "503722-IND",
                        "503696-IND",
                        "503689-IND",
                        "503681-IND",
                        "503675-IND",
                        "503671-IND",
                        "503669-IND",
                        "503639-IND",
                        "503635-IND",
                        "503349-IND",
                        "503310-IND",
                        "503229-IND",
                        "503169-IND",
                        "503162-IND",
                        "503127-IND",
                        "503101-IND",
                        "503100-IND",
                        "503031-IND",
                        "503015-IND",
                        "502986-IND",
                        "502958-IND",
                        "502937-IND",
                        "502933-IND",
                        "502873-IND",
                        "502850-IND",
                        "502820-IND",
                        "502742-IND",
                        "502589-IND",
                        "502460-IND",
                        "502450-IND",
                        "502448-IND",
                        "502445-IND",
                        "502330-IND",
                        "502281-IND",
                        "502271-IND",
                        "502219-IND",
                        "502180-IND",
                        "502175-IND",
                        "502168-IND",
                        "502157-IND",
                        "502137-IND",
                        "501945-IND",
                        "501848-IND",
                        "501833-IND",
                        "501831-IND",
                        "501700-IND",
                        "501630-IND",
                        "501455-IND",
                        "501425-IND",
                        "501423-IND",
                        "501370-IND",
                        "501351-IND",
                        "501311-IND",
                        "501301-IND",
                        "501295-IND",
                        "501242-IND",
                        "501151-IND",
                        "501150-IND",
                        "501148-IND",
                        "501144-IND",
                        "501111-IND",
                        "501110-IND",
                        "500890-IND",
                        "500840-IND",
                        "500800-IND",
                        "500780-IND",
                        "500777-IND",
                        "500770-IND",
                        "500730-IND",
                        "500710-IND",
                        "500690-IND",
                        "500680-IND",
                        "500674-IND",
                        "500672-IND",
                        "500670-IND",
                        "500660-IND",
                        "500655-IND",
                        "500650-IND",
                        "500620-IND",
                        "500570-IND",
                        "500547-IND",
                        "500540-IND",
                        "500530-IND",
                        "500500-IND",
                        "500488-IND",
                        "500483-IND",
                        "500472-IND",
                        "500467-IND",
                        "500464-IND",
                        "500463-IND",
                        "500460-IND",
                        "500459-IND",
                        "500458-IND",
                        "500456-IND",
                        "500450-IND",
                        "500449-IND",
                        "500444-IND",
                        "500439-IND",
                        "500429-IND",
                        "500426-IND",
                        "500422-IND",
                        "500418-IND",
                        "500414-IND",
                        "500413-IND",
                        "500412-IND",
                        "500408-IND",
                        "500407-IND",
                        "500405-IND",
                        "500403-IND",
                        "500402-IND",
                        "500400-IND",
                        "500390-IND",
                        "500378-IND",
                        "500370-IND",
                        "500368-IND",
                        "500367-IND",
                        "500366-IND",
                        "500360-IND",
                        "500358-IND",
                        "500357-IND",
                        "500356-IND",
                        "500350-IND",
                        "500346-IND",
                        "500339-IND",
                        "500338-IND",
                        "500337-IND",
                        "500336-IND",
                        "500333-IND",
                        "500330-IND",
                        "500327-IND",
                        "500325-IND",
                        "500319-IND",
                        "500317-IND",
                        "500314-IND",
                        "500313-IND",
                        "500312-IND",
                        "500307-IND",
                        "500306-IND",
                        "500302-IND",
                        "500300-IND",
                        "500298-IND",
                        "500296-IND",
                        "500290-IND",
                        "500288-IND",
                        "500285-IND",
                        "500284-IND",
                        "500280-IND",
                        "500279-IND",
                        "500277-IND",
                        "500271-IND",
                        "500268-IND",
                        "500267-IND",
                        "500266-IND",
                        "500265-IND",
                        "500264-IND",
                        "500259-IND",
                        "500253-IND",
                        "500252-IND",
                        "500249-IND",
                        "500246-IND",
                        "500245-IND",
                        "500243-IND",
                        "500241-IND",
                        "500240-IND",
                        "500239-IND",
                        "500238-IND",
                        "500236-IND",
                        "500235-IND",
                        "500234-IND",
                        "500233-IND",
                        "500231-IND",
                        "500227-IND",
                        "500223-IND",
                        "500220-IND",
                        "500219-IND",
                        "500215-IND",
                        "500214-IND",
                        "500213-IND",
                        "500212-IND",
                        "500211-IND",
                        "500210-IND",
                        "500207-IND",
                        "500202-IND",
                        "500201-IND",
                        "500199-IND",
                        "500192-IND",
                        "500191-IND",
                        "500189-IND",
                        "500188-IND",
                        "500187-IND",
                        "500186-IND",
                        "500185-IND",
                        "500183-IND",
                        "500179-IND",
                        "500174-IND",
                        "500173-IND",
                        "500171-IND",
                        "500170-IND",
                        "500168-IND",
                        "500166-IND",
                        "500164-IND",
                        "500163-IND",
                        "500160-IND",
                        "500159-IND",
                        "500153-IND",
                        "500151-IND",
                        "500150-IND",
                        "500148-IND",
                        "500147-IND",
                        "500144-IND",
                        "500143-IND",
                        "500142-IND",
                        "500136-IND",
                        "500135-IND",
                        "500133-IND",
                        "500128-IND",
                        "500126-IND",
                        "500125-IND",
                        "500123-IND",
                        "500120-IND",
                        "500119-IND",
                        "500117-IND",
                        "500116-IND",
                        "500111-IND",
                        "500110-IND",
                        "500109-IND",
                        "500108-IND",
                        "500106-IND",
                        "500104-IND",
                        "500103-IND",
                        "500102-IND",
                        "500101-IND",
                        "500097-IND",
                        "500093-IND",
                        "500092-IND",
                        "500089-IND",
                        "500085-IND",
                        "500084-IND",
                        "500083-IND",
                        "500078-IND",
                        "500074-IND",
                        "500069-IND",
                        "500068-IND",
                        "500058-IND",
                        "500052-IND",
                        "500048-IND",
                        "500042-IND",
                        "500041-IND",
                        "500040-IND",
                        "500039-IND",
                        "500034-IND",
                        "500033-IND",
                        "500031-IND",
                        "500028-IND",
                        "500023-IND",
                        "500020-IND",
                        "500016-IND",
                        "500012-IND",
                        "500010-IND",
                        "500009-IND",
                        "500003-IND",
                        "780001-IND",
                        "590134-IND",
                        "590110-IND",
                        "590108-IND",
                        "590107-IND",
                        "590106-IND",
                        "590103-IND",
                        "590065-IND",
                        "590024-IND",
                        "555555-IND",
                        "543259-IND",
                        "543257-IND",
                        "543243-IND",
                        "543242-IND",
                        "543238-IND",
                        "543227-IND",
                        "543223-IND",
                        "543220-IND",
                        "543213-IND",
                        "542932-IND",
                        "542920-IND",
                        "542907-IND",
                        "542857-IND",
                        "542830-IND",
                        "542773-IND",
                        "542760-IND",
                        "542726-IND",
                        "542684-IND",
                        "542669-IND",
                        "542649-IND",
                        "542597-IND",
                        "542592-IND",
                        "542484-IND",
                        "542399-IND",
                        "542351-IND",
                        "542066-IND",
                        "542025-IND",
                        "541988-IND",
                        "541956-IND",
                        "541890-IND",
                        "541729-IND",
                        "541556-IND",
                        "541540-IND",
                        "541353-IND",
                        "541352-IND",
                        "541336-IND",
                        "541195-IND",
                        "541179-IND",
                        "541153-IND",
                        "541151-IND",
                        "540975-IND",
                        "540797-IND",
                        "540776-IND",
                        "540769-IND",
                        "540768-IND",
                        "540767-IND",
                        "540762-IND",
                        "540755-IND",
                        "540750-IND",
                        "540749-IND",
                        "540743-IND",
                        "540716-IND",
                        "540710-IND",
                        "540709-IND",
                        "540703-IND",
                        "540691-IND",
                        "540686-IND",
                        "540680-IND",
                        "540673-IND",
                        "540650-IND",
                        "540642-IND",
                        "540611-IND",
                        "540602-IND",
                        "540596-IND",
                        "540530-IND",
                        "540366-IND",
                        "540293-IND",
                        "540268-IND",
                        "540203-IND",
                        "540153-IND",
                        "540147-IND",
                        "540144-IND",
                        "540064-IND",
                        "539992-IND",
                        "539978-IND",
                        "539940-IND",
                        "539894-IND",
                        "539883-IND",
                        "539874-IND",
                        "539844-IND",
                        "539807-IND",
                        "539787-IND",
                        "539742-IND",
                        "539678-IND",
                        "539658-IND",
                        "539636-IND",
                        "539598-IND",
                        "539551-IND",
                        "539450-IND",
                        "539447-IND",
                        "539437-IND",
                        "539407-IND",
                        "539404-IND",
                        "539351-IND",
                        "539346-IND",
                        "539332-IND",
                        "539331-IND",
                        "539310-IND",
                        "539301-IND",
                        "539278-IND",
                        "539277-IND",
                        "539267-IND",
                        "539251-IND",
                        "539228-IND",
                        "539226-IND",
                        "539225-IND",
                        "539224-IND",
                        "539217-IND",
                        "539216-IND",
                        "539177-IND",
                        "539143-IND",
                        "539142-IND",
                        "539117-IND",
                        "539097-IND",
                        "539091-IND",
                        "539056-IND",
                        "539046-IND",
                        "539043-IND",
                        "539041-IND",
                        "539009-IND",
                        "539005-IND",
                        "538970-IND",
                        "538961-IND",
                        "538952-IND",
                        "538922-IND",
                        "538902-IND",
                        "538891-IND",
                        "538882-IND",
                        "538874-IND",
                        "538788-IND",
                        "538787-IND",
                        "538772-IND",
                        "538743-IND",
                        "538742-IND",
                        "538734-IND",
                        "538731-IND",
                        "538714-IND",
                        "538713-IND",
                        "538707-IND",
                        "538683-IND",
                        "538667-IND",
                        "538653-IND",
                        "538646-IND",
                        "538611-IND",
                        "538607-IND",
                        "538579-IND",
                        "538566-IND",
                        "538565-IND",
                        "538540-IND",
                        "538451-IND",
                        "538446-IND",
                        "538433-IND",
                        "538401-IND",
                        "538119-IND",
                        "538092-IND",
                        "538057-IND",
                        "537785-IND",
                        "537708-IND",
                        "537669-IND",
                        "537524-IND",
                        "537483-IND",
                        "537254-IND",
                        "536868-IND",
                        "536751-IND",
                        "536709-IND",
                        "536170-IND",
                        "535917-IND",
                        "535910-IND",
                        "535647-IND",
                        "535279-IND",
                        "535204-IND",
                        "535136-IND",
                        "534804-IND",
                        "534796-IND",
                        "534755-IND",
                        "534733-IND",
                        "534707-IND",
                        "534674-IND",
                        "534618-IND",
                        "534597-IND",
                        "534532-IND",
                        "534309-IND",
                        "534109-IND",
                        "533638-IND",
                        "533605-IND",
                        "533552-IND",
                        "533540-IND",
                        "533520-IND",
                        "533519-IND",
                        "533506-IND",
                        "533452-IND",
                        "533400-IND",
                        "533326-IND",
                        "533316-IND",
                        "533315-IND",
                        "533295-IND",
                        "533289-IND",
                        "533285-IND",
                        "533272-IND",
                        "533259-IND",
                        "533218-IND",
                        "533204-IND",
                        "533202-IND",
                        "533192-IND",
                        "533189-IND",
                        "533177-IND",
                        "533164-IND",
                        "533162-IND",
                        "533144-IND",
                        "533107-IND",
                        "533095-IND",
                        "533047-IND",
                        "532998-IND",
                        "532987-IND",
                        "532985-IND",
                        "532980-IND",
                        "532975-IND",
                        "532960-IND",
                        "532955-IND",
                        "532952-IND",
                        "532923-IND",
                        "532911-IND",
                        "532907-IND",
                        "532900-IND",
                        "532895-IND",
                        "532891-IND",
                        "532887-IND",
                        "532874-IND",
                        "532873-IND",
                        "532870-IND",
                        "532829-IND",
                        "532823-IND",
                        "532820-IND",
                        "532813-IND",
                        "532798-IND",
                        "532795-IND",
                        "532771-IND",
                        "532746-IND",
                        "532741-IND",
                        "532723-IND",
                        "532716-IND",
                        "532701-IND",
                        "532636-IND",
                        "532626-IND",
                        "532613-IND",
                        "532531-IND",
                        "532507-IND",
                        "532470-IND",
                        "532425-IND",
                        "532419-IND",
                        "532407-IND",
                        "532398-IND",
                        "532397-IND",
                        "532390-IND",
                        "532376-IND",
                        "532372-IND",
                        "532368-IND",
                        "532365-IND",
                        "532362-IND",
                        "532309-IND",
                        "532307-IND",
                        "532285-IND",
                        "532230-IND",
                        "532189-IND",
                        "532172-IND",
                        "532143-IND",
                        "532114-IND",
                        "532113-IND",
                        "532078-IND",
                        "532042-IND",
                        "532033-IND",
                        "532019-IND",
                        "532007-IND",
                        "531994-IND",
                        "531972-IND",
                        "531946-IND",
                        "531944-IND",
                        "531913-IND",
                        "531910-IND",
                        "531909-IND",
                        "531902-IND",
                        "531888-IND",
                        "531881-IND",
                        "531862-IND",
                        "531845-IND",
                        "531841-IND",
                        "531834-IND",
                        "531775-IND",
                        "531717-IND",
                        "531692-IND",
                        "531688-IND",
                        "531668-IND",
                        "531667-IND",
                        "531637-IND",
                        "531628-IND",
                        "531613-IND",
                        "531592-IND",
                        "531578-IND",
                        "531553-IND",
                        "531550-IND",
                        "531541-IND",
                        "531518-IND",
                        "531503-IND",
                        "531489-IND",
                        "531437-IND",
                        "531429-IND",
                        "531426-IND",
                        "531398-IND",
                        "531390-IND",
                        "531381-IND",
                        "531364-IND",
                        "531360-IND",
                        "531340-IND",
                        "531337-IND",
                        "531334-IND",
                        "531328-IND",
                        "531323-IND",
                        "531322-IND",
                        "531307-IND",
                        "531273-IND",
                        "531260-IND",
                        "531254-IND",
                        "531253-IND",
                        "531252-IND",
                        "531241-IND",
                        "531234-IND",
                        "531192-IND",
                        "531163-IND",
                        "531158-IND",
                        "531129-IND",
                        "531127-IND",
                        "531112-IND",
                        "531109-IND",
                        "531099-IND",
                        "531092-IND",
                        "531035-IND",
                        "530977-IND",
                        "530961-IND",
                        "530907-IND",
                        "530889-IND",
                        "530881-IND",
                        "530879-IND",
                        "530855-IND",
                        "530841-IND",
                        "530815-IND",
                        "530779-IND",
                        "530777-IND",
                        "530755-IND",
                        "530741-IND",
                        "530709-IND",
                        "530697-IND",
                        "530677-IND",
                        "530663-IND",
                        "530655-IND",
                        "530627-IND",
                        "530621-IND",
                        "530601-IND",
                        "530595-IND",
                        "530581-IND",
                        "530579-IND",
                        "530557-IND",
                        "530495-IND",
                        "530445-IND",
                        "530355-IND",
                        "530313-IND",
                        "530291-IND",
                        "530265-IND",
                        "530201-IND",
                        "530197-IND",
                        "530141-IND",
                        "530109-IND",
                        "530095-IND",
                        "530067-IND",
                        "530057-IND",
                        "530053-IND",
                        "530047-IND",
                        "526981-IND",
                        "526961-IND",
                        "526935-IND",
                        "526905-IND",
                        "526847-IND",
                        "526721-IND",
                        "526705-IND",
                        "526640-IND",
                        "526638-IND",
                        "526628-IND",
                        "526550-IND",
                        "526525-IND",
                        "526494-IND",
                        "526488-IND",
                        "526473-IND",
                        "526407-IND",
                        "526231-IND",
                        "526173-IND",
                        "526095-IND",
                        "526081-IND",
                        "526025-IND",
                        "524768-IND",
                        "524752-IND",
                        "543219-IND",
                        "780018-IND",
                        "542863-IND",
                        "542230-IND",
                        "541095-IND",
                        "541094-IND",
                        "541093-IND",
                        "541092-IND",
                        "541255-IND",
                        "541254-IND",
                        "541253-IND",
                        "541252-IND",
                        "541068-IND",
                        "541069-IND",
                        "541070-IND",
                        "541071-IND",
                        "540551-IND",
                        "540552-IND",
                        "542244-IND",
                        "542247-IND",
                        "542245-IND",
                        "542246-IND",
                        "540787-IND",
                        "540008-IND",
                        "780013-IND",
                        "541086-IND",
                        "541087-IND",
                        "541084-IND",
                        "541085-IND",
                        "780014-IND",
                        "543224-IND",
                        "540592-IND",
                        "540594-IND",
                        "540593-IND",
                        "540591-IND",
                        "540635-IND",
                        "540634-IND",
                        "540637-IND",
                        "540636-IND",
                        "539516-IND",
                        "539517-IND",
                        "541804-IND",
                        "541806-IND",
                        "541807-IND",
                        "541805-IND",
                        "542730-IND",
                        "541674-IND",
                        "541672-IND",
                        "541675-IND",
                        "541673-IND",
                        "541309-IND",
                        "541311-IND",
                        "541312-IND",
                        "541310-IND",
                        "542160-IND",
                        "542163-IND",
                        "542156-IND",
                        "542158-IND",
                        "538729-IND",
                        "538728-IND",
                        "543221-IND",
                        "542921-IND",
                        "539980-IND",
                        "537008-IND",
                        "537007-IND",
                        "540612-IND",
                        "541809-IND",
                        "539945-IND",
                        "542758-IND",
                        "541313-IND",
                        "540863-IND",
                        "540862-IND",
                        "540861-IND",
                        "540864-IND",
                        "540947-IND",
                        "540950-IND",
                        "540951-IND",
                        "540949-IND",
                        "540763-IND",
                        "540765-IND",
                        "540766-IND",
                        "540764-IND",
                        "541728-IND",
                        "541727-IND",
                        "541726-IND",
                        "541725-IND",
                        "540154-IND",
                        "541346-IND",
                        "541345-IND",
                        "541343-IND",
                        "541341-IND",
                        "541884-IND",
                        "541882-IND",
                        "541881-IND",
                        "541879-IND",
                        "539784-IND",
                        "539480-IND",
                        "539487-IND",
                        "543246-IND",
                        "542131-IND",
                        "542922-IND",
                        "536960-IND",
                        "543181-IND",
                        "543182-IND",
                        "543183-IND",
                        "543179-IND",
                        "543180-IND",
                        "543184-IND",
                        "543185-IND",
                        "543186-IND",
                        "543173-IND",
                        "543174-IND",
                        "543175-IND",
                        "543176-IND",
                        "543177-IND",
                        "543178-IND",
                        "542819-IND",
                        "542843-IND",
                        "542814-IND",
                        "542815-IND",
                        "542817-IND",
                        "542818-IND",
                        "542844-IND",
                        "542845-IND",
                        "542839-IND",
                        "542846-IND",
                        "542820-IND",
                        "542841-IND",
                        "542816-IND",
                        "542813-IND",
                        "543149-IND",
                        "543151-IND",
                        "543145-IND",
                        "543170-IND",
                        "543153-IND",
                        "543147-IND",
                        "543148-IND",
                        "543155-IND",
                        "543168-IND",
                        "543156-IND",
                        "543169-IND",
                        "543150-IND",
                        "543144-IND",
                        "543146-IND",
                        "543152-IND",
                        "543154-IND",
                        "542810-IND",
                        "542806-IND",
                        "542808-IND",
                        "542836-IND",
                        "542837-IND",
                        "542838-IND",
                        "542811-IND",
                        "542840-IND",
                        "542805-IND",
                        "542842-IND",
                        "542804-IND",
                        "542847-IND",
                        "542849-IND",
                        "542848-IND",
                        "542809-IND",
                        "542807-IND",
                        "543255-IND",
                        "542747-IND",
                        "540778-IND",
                        "540779-IND",
                        "540780-IND",
                        "540781-IND",
                        "541987-IND",
                        "541986-IND",
                        "541985-IND",
                        "541984-IND",
                        "541972-IND",
                        "570005-IND",
                        "780008-IND",
                        "541743-IND",
                        "541745-IND",
                        "541744-IND",
                        "541742-IND",
                        "541677-IND",
                        "541679-IND",
                        "541676-IND",
                        "541678-IND",
                        "543226-IND",
                        "540669-IND",
                        "542513-IND",
                        "780016-IND",
                        "540789-IND",
                        "541799-IND",
                        "522229-IND",
                        "539660-IND",
                        "524534-IND",
                        "543266-IND",
                        "524709-IND",
                        "543207-IND",
                        "539956-IND",
                        "513430-IND",
                        "511208-IND",
                        "520119-IND",
                        "505712-IND",
                        "505893-IND",
                        "520057-IND",
                        "542459-IND",
                        "500250-IND",
                        "543214-IND",
                        "506122-IND",
                        "539353-IND",
                        "522073-IND",
                        "508571-IND",
                        "542013-IND",
                        "543279-IND",
                        "542904-IND",
                        "514336-IND",
                        "539470-IND",
                        "517415-IND",
                        "540590-IND",
                        "507526-IND",
                        "507435-IND",
                        "507522-IND",
                        "507514-IND",
                        "540023-IND",
                        "540821-IND",
                        "503691-IND",
                        "540083-IND",
                        "539620-IND",
                        "540061-IND",
                        "539393-IND",
                        "541503-IND",
                        "542012-IND",
                        "512038-IND",
                        "512165-IND",
                        "541152-IND",
                        "506120-IND",
                        "512008-IND",
                        "542524-IND",
                        "542721-IND",
                        "512344-IND",
                        "541702-IND",
                        "519532-IND",
                        "512433-IND",
                        "523896-IND",
                        "539834-IND",
                        "539546-IND",
                        "501430-IND",
                        "539600-IND",
                        "512301-IND",
                        "542627-IND",
                        "540829-IND",
                        "539800-IND",
                        "539991-IND",
                        "523844-IND",
                        "540361-IND",
                        "540695-IND",
                        "540811-IND",
                        "542155-IND",
                        "539533-IND",
                        "542803-IND",
                        "506180-IND",
                        "530929-IND",
                        "504380-IND",
                        "540190-IND",
                        "512443-IND",
                        "540613-IND",
                        "540936-IND",
                        "540266-IND",
                        "541276-IND",
                        "540377-IND",
                        "539449-IND",
                        "524675-IND",
                        "540696-IND",
                        "539814-IND",
                        "540401-IND",
                        "540730-IND",
                        "540809-IND",
                        "501477-IND",
                        "535205-IND",
                        "539409-IND",
                        "512522-IND",
                        "540243-IND",
                        "542206-IND",
                        "522289-IND",
                        "524414-IND",
                        "542628-IND",
                        "542694-IND",
                        "504335-IND",
                        "540717-IND",
                        "501314-IND",
                        "540270-IND",
                        "515127-IND",
                        "514223-IND",
                        "539561-IND",
                        "504360-IND",
                        "542145-IND",
                        "539922-IND",
                        "540132-IND",
                        "542232-IND",
                        "539584-IND",
                        "540221-IND",
                        "540653-IND",
                        "540914-IND",
                        "540492-IND",
                        "512381-IND",
                        "539911-IND",
                        "539406-IND",
                        "540332-IND",
                        "512011-IND",
                        "540980-IND",
                        "541741-IND",
                        "540726-IND",
                        "540570-IND",
                        "542655-IND",
                        "540823-IND",
                        "501391-IND",
                        "511601-IND",
                        "505690-IND",
                        "570001-IND",
                        "506248-IND",
                        "524434-IND",
                        "541269-IND",
                        "500645-IND",
                        "543252-IND",
                        "505710-IND",
                        "506640-IND",
                        "506525-IND",
                        "514248-IND",
                        "524488-IND",
                        "507753-IND",
                        "506808-IND",
                        "540923-IND",
                        "542057-IND",
                        "541302-IND",
                        "541546-IND",
                        "540134-IND",
                        "514360-IND",
                        "543240-IND",
                        "540150-IND",
                        "541945-IND",
                        "520111-IND",
                        "541112-IND",
                        "539861-IND",
                        "541735-IND",
                        "536846-IND",
                        "502250-IND",
                        "515093-IND",
                        "502294-IND",
                        "541634-IND",
                        "540072-IND",
                        "543284-IND",
                        "542851-IND",
                        "513295-IND",
                        "542446-IND",
                        "541196-IND",
                        "539927-IND",
                        "540204-IND",
                        "530805-IND",
                        "503641-IND",
                        "539982-IND",
                        "542865-IND",
                        "539986-IND",
                        "542666-IND",
                        "515145-IND",
                        "540078-IND",
                        "539354-IND",
                        "539337-IND",
                        "540879-IND",
                        "570002-IND",
                        "542337-IND",
                        "539632-IND",
                        "539661-IND",
                        "541865-IND",
                        "540146-IND",
                        "542437-IND",
                        "539788-IND",
                        "539599-IND",
                        "539519-IND",
                        "543262-IND",
                        "539525-IND",
                        "539673-IND",
                        "539526-IND",
                        "539518-IND",
                        "511463-IND",
                        "523319-IND",
                        "543209-IND",
                        "542924-IND",
                        "540728-IND",
                        "519471-IND",
                        "524332-IND",
                        "519477-IND",
                        "507543-IND",
                        "524444-IND",
                        "539725-IND",
                        "519494-IND",
                        "519359-IND",
                        "541401-IND",
                        "540403-IND",
                        "540062-IND",
                        "508918-IND",
                        "539921-IND",
                        "539682-IND",
                        "539686-IND",
                        "542323-IND",
                        "503657-IND",
                        "539984-IND",
                        "517172-IND",
                        "511034-IND",
                        "539917-IND",
                        "542123-IND",
                        "523736-IND",
                        "542377-IND",
                        "539391-IND",
                        "539506-IND",
                        "539493-IND",
                        "539773-IND",
                        "541133-IND",
                        "542774-IND",
                        "542911-IND",
                        "511589-IND",
                        "539946-IND",
                        "539621-IND",
                        "539662-IND",
                        "543208-IND",
                        "509470-IND",
                        "539434-IND",
                        "539681-IND",
                        "542906-IND",
                        "539552-IND",
                        "539839-IND",
                        "539492-IND",
                        "508956-IND",
                        "539692-IND",
                        "506161-IND",
                        "507987-IND",
                        "511728-IND",
                        "539679-IND",
                        "512597-IND",
                        "539384-IND",
                        "539408-IND",
                        "539909-IND",
                        "537800-IND",
                        "540254-IND",
                        "500206-IND",
                        "501343-IND",
                        "523862-IND",
                        "511597-IND",
                        "539481-IND",
                        "539495-IND",
                        "539669-IND",
                        "543256-IND",
                        "511169-IND",
                        "540181-IND",
                        "507952-IND",
                        "539683-IND",
                        "539833-IND",
                        "541358-IND",
                        "512197-IND",
                        "539494-IND",
                        "532138-IND",
                        "540211-IND",
                        "512221-IND",
                        "508963-IND",
                        "511654-IND",
                        "508969-IND",
                        "539574-IND",
                        "539835-IND",
                        "540168-IND",
                        "539468-IND",
                        "511742-IND",
                        "500014-IND",
                        "542931-IND",
                        "509038-IND",
                        "519224-IND",
                        "536565-IND",
                        "542667-IND",
                        "540775-IND",
                        "543231-IND",
                        "516003-IND",
                        "516062-IND",
                        "540954-IND",
                        "541337-IND",
                        "540904-IND",
                        "539433-IND",
                        "539378-IND",
                        "539428-IND",
                        "540694-IND",
                        "542919-IND",
                        "542248-IND",
                        "541299-IND",
                        "542918-IND",
                        "523323-IND",
                        "540702-IND",
                        "540468-IND",
                        "543264-IND",
                        "542580-IND",
                        "540614-IND",
                        "540136-IND",
                        "522091-IND",
                        "500490-IND",
                        "523269-IND",
                        "500193-IND",
                        "541973-IND",
                        "507966-IND",
                        "542599-IND",
                        "540782-IND",
                        "543260-IND",
                        "543212-IND",
                        "541083-IND",
                        "540697-IND",
                        "541402-IND",
                        "543236-IND",
                        "543172-IND",
                        "502865-IND",
                        "539479-IND",
                        "542682-IND",
                        "505299-IND",
                        "505320-IND",
                        "540519-IND",
                        "542801-IND",
                        "543270-IND",
                        "505658-IND",
                        "505790-IND",
                        "512297-IND",
                        "522294-IND",
                        "541228-IND",
                        "542460-IND",
                        "522171-IND",
                        "539398-IND",
                        "519319-IND",
                        "540704-IND",
                        "540416-IND",
                        "543228-IND",
                        "512161-IND",
                        "543281-IND",
                        "511447-IND",
                        "502893-IND",
                        "542367-IND",
                        "540647-IND",
                        "501298-IND",
                        "539947-IND",
                        "540648-IND",
                        "539875-IND",
                        "503663-IND",
                        "539488-IND",
                        "539543-IND",
                        "539799-IND",
                        "504648-IND",
                        "513343-IND",
                        "539597-IND",
                        "506079-IND",
                        "523832-IND",
                        "540082-IND",
                        "540393-IND",
                        "540425-IND",
                        "541701-IND",
                        "539798-IND",
                        "539455-IND",
                        "512404-IND",
                        "506024-IND",
                        "540744-IND",
                        "540080-IND",
                        "513179-IND",
                        "513566-IND",
                        "513414-IND",
                        "500404-IND",
                        "500055-IND",
                        "513010-IND",
                        "500365-IND",
                        "504998-IND",
                        "511144-IND",
                        "543268-IND",
                        "540063-IND",
                        "540654-IND",
                        "539724-IND",
                        "542651-IND",
                        "539383-IND",
                        "542117-IND",
                        "539843-IND",
                        "542333-IND",
                        "540143-IND",
                        "543234-IND",
                        "540174-IND",
                        "542233-IND",
                        "517429-IND",
                        "539607-IND",
                        "506935-IND",
                        "509051-IND",
                        "540735-IND",
                        "502015-IND",
                        "504918-IND",
                        "513498-IND",
                        "501622-IND",
                        "543254-IND",
                        "512441-IND",
                        "542668-IND",
                        "505585-IND",
                        "512377-IND",
                        "512303-IND",
                        "511187-IND",
                        "504356-IND",
                        "512004-IND",
                        "508875-IND",
                        "508924-IND",
                        "506107-IND",
                        "512153-IND",
                        "503659-IND",
                        "513305-IND",
                        "512117-IND",
                        "512060-IND",
                        "512064-IND",
                        "540151-IND",
                        "539910-IND",
                        "531512-IND",
                        "539469-IND",
                        "540159-IND",
                        "540084-IND",
                        "542670-IND",
                        "506480-IND",
                        "540025-IND",
                        "541999-IND",
                        "539854-IND",
                        "519463-IND",
                        "541304-IND",
                        "539594-IND",
                        "539762-IND",
                        "540404-IND",
                        "524542-IND",
                        "539402-IND",
                        "513713-IND",
                        "541144-IND",
                        "542020-IND",
                        "541771-IND",
                        "542579-IND",
                        "540545-IND",
                        "540148-IND",
                        "539884-IND",
                        "539405-IND",
                        "539559-IND",
                        "541403-IND",
                        "542850-IND",
                        "523676-IND",
                        "542653-IND",
                        "543278-IND",
                        "540953-IND",
                        "540952-IND",
                        "539400-IND",
                        "541444-IND",
                        "540467-IND",
                        "539401-IND",
                        "540125-IND",
                        "543171-IND",
                        "542034-IND",
                        "540048-IND",
                        "540715-IND",
                        "542725-IND",
                        "542728-IND",
                        "541967-IND",
                        "540269-IND",
                        "541338-IND",
                        "540252-IND",
                        "543230-IND",
                        "500060-IND",
                        "504286-IND",
                        "542679-IND",
                        "540795-IND",
                        "504697-IND",
                        "540651-IND",
                        "515008-IND",
                        "513519-IND",
                        "539760-IND",
                        "540358-IND",
                        "517273-IND",
                        "540786-IND",
                        "542905-IND",
                        "541445-IND",
                        "539562-IND",
                        "532974-IND",
                        "543235-IND",
                        "540135-IND",
                        "542176-IND",
                        "511501-IND",
                        "539637-IND",
                        "539304-IND",
                        "511696-IND",
                        "540481-IND",
                        "543232-IND",
                        "540199-IND",
                        "539770-IND",
                        "539596-IND",
                        "541053-IND",
                        "540455-IND",
                        "504346-IND",
                        "542802-IND",
                        "511441-IND",
                        "542332-IND",
                        "542772-IND",
                        "514238-IND",
                        "542866-IND",
                        "542544-IND",
                        "540515-IND",
                        "540385-IND",
                        "501261-IND",
                        "540360-IND",
                        "540192-IND",
                        "539938-IND",
                        "509760-IND",
                        "590115-IND",
                        "539819-IND",
                        "511401-IND",
                        "538395-IND",
                        "539521-IND",
                        "590104-IND",
                        "590109-IND",
                        "540386-IND",
                        "504341-IND",
                        "540175-IND",
                        "539435-IND",
                        "503622-IND",
                        "539895-IND",
                        "540725-IND",
                        "512367-IND",
                        "539593-IND",
                        "540253-IND",
                        "543263-IND",
                        "542376-IND",
                        "512215-IND",
                        "503624-IND",
                        "501386-IND",
                        "509945-IND",
                        "532159-IND",
                        "540097-IND",
                        "539560-IND",
                        "539570-IND",
                        "507552-IND",
                        "507474-IND",
                        "507598-IND",
                        "541974-IND",
                        "540405-IND",
                        "524582-IND",
                        "539963-IND",
                        "541178-IND",
                        "540652-IND",
                        "517514-IND",
                        "540774-IND",
                        "505693-IND",
                        "539837-IND",
                        "543065-IND",
                        "543211-IND",
                        "539527-IND",
                        "504786-IND",
                        "540727-IND",
                        "539359-IND",
                        "543229-IND",
                        "540079-IND",
                        "541929-IND",
                        "507265-IND",
                        "539528-IND",
                        "511153-IND",
                        "540692-IND",
                        "519500-IND",
                        "540681-IND",
                        "506186-IND",
                        "542935-IND",
                        "540850-IND",
                        "543253-IND",
                        "541418-IND",
                        "519506-IND",
                        "540724-IND",
                        "519260-IND",
                        "531925-IND",
                        "519397-IND",
                        "540757-IND",
                        "519604-IND",
                        "540955-IND",
                        "516092-IND",
                        "540824-IND",
                        "523186-IND",
                        "541627-IND",
                        "502587-IND",
                        "502420-IND",
                        "539785-IND",
                        "538795-IND",
                        "502563-IND",
                        "500388-IND",
                        "516086-IND",
                        "541633-IND",
                        "516030-IND",
                        "542771-IND",
                        "540108-IND",
                        "524412-IND",
                        "539872-IND",
                        "541096-IND",
                        "524396-IND",
                        "542934-IND",
                        "542678-IND",
                        "540701-IND",
                        "542724-IND",
                        "506414-IND",
                        "539730-IND",
                        "539680-IND",
                        "524164-IND",
                        "524400-IND",
                        "524604-IND",
                        "539997-IND",
                        "540937-IND",
                        "500274-IND",
                        "540359-IND",
                        "541601-IND",
                        "540737-IND",
                        "524632-IND",
                        "540679-IND",
                        "524727-IND",
                        "540145-IND",
                        "540729-IND",
                        "524711-IND",
                        "524661-IND",
                        "541400-IND",
                        "542933-IND",
                        "516064-IND",
                        "543239-IND",
                        "570004-IND",
                        "500248-IND",
                        "517431-IND",
                        "540693-IND",
                        "542685-IND",
                        "539515-IND",
                        "540497-IND",
                        "540081-IND",
                        "539522-IND",
                        "539697-IND",
                        "539544-IND",
                        "500343-IND",
                        "543249-IND",
                        "500013-IND",
                        "501270-IND",
                        "540621-IND",
                        "502216-IND",
                        "542002-IND",
                        "523329-IND",
                        "540152-IND",
                        "508929-IND",
                        "541161-IND",
                        "540026-IND",
                        "512048-IND",
                        "542677-IND",
                        "540402-IND",
                        "539767-IND",
                        "542864-IND",
                        "542231-IND",
                        "523628-IND",
                        "509845-IND",
                        "540796-IND",
                        "543251-IND",
                        "542146-IND",
                        "539363-IND",
                        "543218-IND",
                        "542046-IND",
                        "505583-IND",
                        "539403-IND",
                        "543283-IND",
                        "539659-IND",
                        "540700-IND",
                        "519413-IND",
                        "543273-IND",
                        "520151-IND",
                        "541983-IND",
                        "540756-IND",
                        "540843-IND",
                        "540259-IND",
                        "543210-IND",
                        "540066-IND",
                        "543233-IND",
                        "540395-IND",
                        "513353-IND",
                        "541778-IND",
                        "541557-IND",
                        "542812-IND",
                        "500184-IND",
                        "523586-IND",
                        "506945-IND",
                        "543271-IND",
                        "514448-IND",
                        "543277-IND",
                        "542665-IND",
                        "524324-IND",
                        "539334-IND",
                        "539939-IND",
                        "541167-IND",
                        "540550-IND",
                        "540615-IND",
                        "540205-IND",
                        "540956-IND",
                        "542727-IND",
                        "541703-IND",
                        "540027-IND",
                        "540901-IND",
                        "540210-IND",
                        "542654-IND",
                        "517494-IND",
                        "540649-IND",
                        "500032-IND",
                        "543267-IND",
                        "507442-IND",
                        "541347-IND",
                        "507498-IND",
                        "500354-IND",
                        "507450-IND",
                        "542852-IND",
                        "542753-IND",
                        "508136-IND",
                        "519353-IND",
                        "509715-IND",
                        "541005-IND",
                        "542938-IND",
                        "519191-IND",
                        "517214-IND",
                        "514274-IND",
                        "540718-IND",
                        "541303-IND",
                        "541006-IND",
                        "542285-IND",
                        "539399-IND",
                        "521244-IND",
                        "540310-IND",
                        "542729-IND",
                        "521014-IND",
                        "509557-IND",
                        "590025-IND",
                        "540938-IND",
                        "502901-IND",
                        "512036-IND",
                        "514240-IND",
                        "540396-IND",
                        "514330-IND",
                        "540198-IND",
                        "503092-IND",
                        "514028-IND",
                        "543274-IND",
                        "514280-IND",
                        "542862-IND",
                        "514402-IND",
                        "543244-IND",
                        "540961-IND",
                        "542019-IND",
                        "540318-IND",
                        "539985-IND",
                        "507808-IND",
                        "507817-IND",
                        "540311-IND",
                        "526737-IND",
                        "543193-IND",
                        "540006-IND",
                        "540798-IND",
                        "539841-IND",
                        "541206-IND",
                        "542383-IND",
                        "540738-IND",
                        "540073-IND",
                        "543272-IND",
                        "513496-IND"
                    ],
                    "id": 1248,
                    "name": "INDIA"
                },
                {
                    "symbols": [
                        "FB-USA",
                        "GOOG-USA",
                        "BA-USA",
                        "AAPL-USA",
                        "HPQ-USA",
                        "MSFT-USA",
                        "IBM-USA",
                        "JPM-USA",
                        "XOM-USA",
                        "YHOO-USA",
                        "GE-USA",
                        "SBUX-USA",
                        "MO-USA",
                        "PFE-USA",
                        "AA-USA",
                        "FMC-USA",
                        "GS-USA",
                        "CAG-USA",
                        "BHI-USA",
                        "MCD-USA",
                        "MON-USA",
                        "PPG-USA",
                        "AMGN-USA",
                        "T-USA",
                        "VZ-USA",
                        "DUK-USA",
                        "ETR-USA",
                        "INTC-USA",
                        "IPG-USA",
                        "OMC-USA",
                        "GPS-USA",
                        "LB-USA",
                        "ROST-USA",
                        "TJX-USA",
                        "URBN-USA",
                        "COH-USA",
                        "FOSL-USA",
                        "NKE-USA",
                        "KORS-USA",
                        "RL-USA",
                        "PVH-USA",
                        "TIF-USA",
                        "UA-USA",
                        "VFC-USA",
                        "BWA-USA",
                        "DLPH-USA",
                        "JCI-USA",
                        "F-USA",
                        "GM-USA",
                        "CVC-USA",
                        "CBS-USA",
                        "CMCSA-USA",
                        "DTV-USA",
                        "DISCA-USA",
                        "SNI-USA",
                        "TWX-USA",
                        "TWC-USA",
                        "VIAB-USA",
                        "DIS-USA",
                        "WYNN-USA",
                        "BBY-USA",
                        "DHI-USA",
                        "GRMN-USA",
                        "HAR-USA",
                        "M-USA",
                        "JWN-USA",
                        "DG-USA",
                        "DLTR-USA",
                        "FDO-USA",
                        "KSS-USA",
                        "TGT-USA",
                        "MHK-USA",
                        "HD-USA",
                        "LOW-USA",
                        "LEN-USA",
                        "PHM-USA",
                        "CCL-USA",
                        "EXPE-USA",
                        "MAR-USA",
                        "PCLN-USA",
                        "HOT-USA",
                        "WYN-USA",
                        "SNA-USA",
                        "SWK-USA",
                        "WHR-USA",
                        "NWL-USA",
                        "AMZN-USA",
                        "TRIP-USA",
                        "HAS-USA",
                        "MAT-USA",
                        "HOG-USA",
                        "GCI-USA",
                        "NWSA-USA",
                        "FOXA-USA",
                        "GHC-USA",
                        "CMG-USA",
                        "DRI-USA",
                        "YUM-USA",
                        "TSCO-USA",
                        "AN-USA",
                        "AZO-USA",
                        "BBBY-USA",
                        "KMX-USA",
                        "GME-USA",
                        "GPC-USA",
                        "ORLY-USA",
                        "PETM-USA",
                        "SPLS-USA",
                        "GT-USA",
                        "EMC-USA",
                        "JNPR-USA",
                        "BAC-USA",
                        "C-USA",
                        "FSLR-USA",
                        "CHK-USA",
                        "CVX-USA",
                        "HES-USA",
                        "MUR-USA",
                        "DO-USA",
                        "ESV-USA",
                        "HP-USA",
                        "NBR-USA",
                        "NE-USA",
                        "RDC-USA",
                        "RIG-USA",
                        "CAM-USA",
                        "FTI-USA",
                        "HAL-USA",
                        "NOV-USA",
                        "SLB-USA",
                        "APC-USA",
                        "APA-USA",
                        "COG-USA",
                        "XEC-USA",
                        "COP-USA",
                        "CNX-USA",
                        "DNR-USA",
                        "DVN-USA",
                        "EOG-USA",
                        "EQT-USA",
                        "MRO-USA",
                        "NFX-USA",
                        "NBL-USA",
                        "OXY-USA",
                        "OKE-USA",
                        "BTU-USA",
                        "PXD-USA",
                        "QEP-USA",
                        "RRC-USA",
                        "SWN-USA",
                        "WMB-USA",
                        "KMI-USA",
                        "MPC-USA",
                        "PSX-USA",
                        "SE-USA",
                        "TSO-USA",
                        "VLO-USA",
                        "AMG-USA",
                        "BBT-USA",
                        "CMA-USA",
                        "FITB-USA",
                        "HCBK-USA",
                        "HBAN-USA",
                        "KEY-USA",
                        "MTB-USA",
                        "PBCT-USA",
                        "PNC-USA",
                        "STI-USA",
                        "BK-USA",
                        "USB-USA",
                        "WFC-USA",
                        "ZION-USA",
                        "AXP-USA",
                        "HRB-USA",
                        "COF-USA",
                        "DFS-USA",
                        "NAVI-USA",
                        "AMP-USA",
                        "BLK-USA",
                        "SCHW-USA",
                        "CME-USA",
                        "EFX-USA",
                        "ETFC-USA",
                        "BEN-USA",
                        "ICE-USA",
                        "IVZ-USA",
                        "LM-USA",
                        "MHFI-USA",
                        "MCO-USA",
                        "MS-USA",
                        "NDAQ-USA",
                        "NTRS-USA",
                        "PFG-USA",
                        "PLD-USA",
                        "PRU-USA",
                        "RF-USA",
                        "STT-USA",
                        "TROW-USA",
                        "UNM-USA",
                        "VTR-USA",
                        "AON-USA",
                        "MMC-USA",
                        "AFL-USA",
                        "GNW-USA",
                        "MET-USA",
                        "TMK-USA",
                        "AIZ-USA",
                        "LNC-USA",
                        "BRKB-USA",
                        "LUK-USA",
                        "L-USA",
                        "ACE-USA",
                        "ALL-USA",
                        "AIG-USA",
                        "CB-USA",
                        "CINF-USA",
                        "HIG-USA",
                        "PGR-USA",
                        "TRV-USA",
                        "XL-USA",
                        "CBG-USA",
                        "AMT-USA",
                        "AIV-USA",
                        "AVB-USA",
                        "BXP-USA",
                        "CCI-USA",
                        "EQR-USA",
                        "GGP-USA",
                        "HCP-USA",
                        "HCN-USA",
                        "HST-USA",
                        "KIM-USA",
                        "PCL-USA",
                        "PSA-USA",
                        "SPG-USA",
                        "VNO-USA",
                        "WY-USA",
                        "ESS-USA",
                        "MAC-USA",
                        "ALXN-USA",
                        "BIIB-USA",
                        "CELG-USA",
                        "GILD-USA",
                        "REGN-USA",
                        "VRTX-USA",
                        "ABC-USA",
                        "BMY-USA",
                        "CAH-USA",
                        "CERN-USA",
                        "ESRX-USA",
                        "MCK-USA",
                        "WAT-USA",
                        "ABT-USA",
                        "A-USA",
                        "BCR-USA",
                        "BAX-USA",
                        "BDX-USA",
                        "BSX-USA",
                        "CFN-USA",
                        "COV-USA",
                        "EW-USA",
                        "HSP-USA",
                        "ISRG-USA",
                        "JNJ-USA",
                        "MDT-USA",
                        "PKI-USA",
                        "STJ-USA",
                        "SYK-USA",
                        "TMO-USA",
                        "VAR-USA",
                        "ZMH-USA",
                        "DVA-USA",
                        "LH-USA",
                        "DGX-USA",
                        "THC-USA",
                        "XRAY-USA",
                        "PDCO-USA",
                        "AET-USA",
                        "CI-USA",
                        "HUM-USA",
                        "UNH-USA",
                        "WLP-USA",
                        "ABBV-USA",
                        "ACT-USA",
                        "AGN-USA",
                        "LLY-USA",
                        "MRK-USA",
                        "MYL-USA",
                        "PRGO-USA",
                        "ZTS-USA",
                        "FLIR-USA",
                        "GD-USA",
                        "LMT-USA",
                        "NOC-USA",
                        "RTN-USA",
                        "CHRW-USA",
                        "EXPD-USA",
                        "FDX-USA",
                        "UPS-USA",
                        "DAL-USA",
                        "LUV-USA",
                        "ALLE-USA",
                        "FAST-USA",
                        "MAS-USA",
                        "GLW-USA",
                        "CAT-USA",
                        "DE-USA",
                        "PCAR-USA",
                        "DNB-USA",
                        "IRM-USA",
                        "PBI-USA",
                        "ADT-USA",
                        "CTAS-USA",
                        "FLR-USA",
                        "APH-USA",
                        "AME-USA",
                        "WM-USA",
                        "MMM-USA",
                        "ETN-USA",
                        "EMR-USA",
                        "HON-USA",
                        "IR-USA",
                        "JEC-USA",
                        "LLL-USA",
                        "LEG-USA",
                        "PLL-USA",
                        "PH-USA",
                        "PNR-USA",
                        "PCP-USA",
                        "PWR-USA",
                        "RSG-USA",
                        "RHI-USA",
                        "ROK-USA",
                        "COL-USA",
                        "ROP-USA",
                        "R-USA",
                        "SRCL-USA",
                        "TXT-USA",
                        "TYC-USA",
                        "UTX-USA",
                        "XYL-USA",
                        "CMI-USA",
                        "DHR-USA",
                        "DOV-USA",
                        "FLS-USA",
                        "ITW-USA",
                        "JOY-USA",
                        "GWW-USA",
                        "CSX-USA",
                        "KSU-USA",
                        "NSC-USA",
                        "UNP-USA",
                        "NLSN-USA",
                        "MLM-USA",
                        "VMC-USA",
                        "DOW-USA",
                        "DD-USA",
                        "EMN-USA",
                        "LYB-USA",
                        "SIAL-USA",
                        "ATI-USA",
                        "FCX-USA",
                        "CF-USA",
                        "MOS-USA",
                        "NEM-USA",
                        "APD-USA",
                        "ARG-USA",
                        "PX-USA",
                        "BLL-USA",
                        "OI-USA",
                        "AVY-USA",
                        "BMS-USA",
                        "MWV-USA",
                        "SEE-USA",
                        "IP-USA",
                        "ECL-USA",
                        "IFF-USA",
                        "SHW-USA",
                        "NUE-USA",
                        "ADM-USA",
                        "TAP-USA",
                        "BFB-USA",
                        "STZ-USA",
                        "CVS-USA",
                        "WAG-USA",
                        "SYY-USA",
                        "KR-USA",
                        "SWY-USA",
                        "WFM-USA",
                        "CL-USA",
                        "KMB-USA",
                        "CLX-USA",
                        "COST-USA",
                        "WMT-USA",
                        "CPB-USA",
                        "GIS-USA",
                        "HRL-USA",
                        "K-USA",
                        "GMCR-USA",
                        "KRFT-USA",
                        "MKC-USA",
                        "MJN-USA",
                        "MDLZ-USA",
                        "SJM-USA",
                        "HSY-USA",
                        "TSN-USA",
                        "AVP-USA",
                        "EL-USA",
                        "PG-USA",
                        "CCE-USA",
                        "DPS-USA",
                        "MNST-USA",
                        "PEP-USA",
                        "KO-USA",
                        "LO-USA",
                        "PM-USA",
                        "RAI-USA",
                        "ADBE-USA",
                        "ADSK-USA",
                        "EA-USA",
                        "ORCL-USA",
                        "SYMC-USA",
                        "TDC-USA",
                        "SNDK-USA",
                        "STX-USA",
                        "WDC-USA",
                        "ADS-USA",
                        "TEL-USA",
                        "AKAM-USA",
                        "ADP-USA",
                        "CTXS-USA",
                        "EBAY-USA",
                        "FIS-USA",
                        "FISV-USA",
                        "GOOGL-USA",
                        "INTU-USA",
                        "MA-USA",
                        "NTAP-USA",
                        "NFLX-USA",
                        "PAYX-USA",
                        "CRM-USA",
                        "TSS-USA",
                        "VRSN-USA",
                        "V-USA",
                        "WU-USA",
                        "ACN-USA",
                        "CTSH-USA",
                        "CSC-USA",
                        "JBL-USA",
                        "XRX-USA",
                        "CSCO-USA",
                        "FFIV-USA",
                        "AMAT-USA",
                        "KLAC-USA",
                        "LRCX-USA",
                        "ALTR-USA",
                        "ADI-USA",
                        "AVGO-USA",
                        "BRCM-USA",
                        "LLTC-USA",
                        "MCHP-USA",
                        "MU-USA",
                        "NVDA-USA",
                        "QCOM-USA",
                        "TXN-USA",
                        "XLNX-USA",
                        "CA-USA",
                        "RHT-USA",
                        "HRS-USA",
                        "MSI-USA",
                        "CTL-USA",
                        "FTR-USA",
                        "WIN-USA",
                        "AES-USA",
                        "AEP-USA",
                        "GAS-USA",
                        "TEG-USA",
                        "AEE-USA",
                        "CNP-USA",
                        "CMS-USA",
                        "ED-USA",
                        "D-USA",
                        "DTE-USA",
                        "EIX-USA",
                        "EXC-USA",
                        "FE-USA",
                        "NEE-USA",
                        "NI-USA",
                        "NU-USA",
                        "NRG-USA",
                        "PCG-USA",
                        "POM-USA",
                        "PNW-USA",
                        "PPL-USA",
                        "PEG-USA",
                        "SCG-USA",
                        "SRE-USA",
                        "SO-USA",
                        "TE-USA",
                        "WEC-USA",
                        "XEL-USA"
                    ],
                    "id": 745,
                    "name": "S&P500"
                },
                {
                    "symbols": [
                        "DOM-GBR",
                        "DOM-SWE",
                        "DOM-ESP",
                    ],
                    "id": 746,
                    "name": "MY TICKERS"
                },
                {
                    "symbols": [
                        "MSFT-USA",
                        "MO-USA",
                        "PFE-USA",
                    ],
                    "id": 747,
                    "name": "TEST 2"
                }
            ],
            "id": 311,
            "entitlement": "call-all,news-dj,news-pr,webnews-article",
            "name": "tapan@alexandriatechnology.com"
        }
    } else {
        try {
            const response = await fetch(`${baseUrl}/v2/user/details/`);
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const data = await response.json();
            if (data.status === 'unauthorised') {
                window.location.href = '/sign_in';
            }
            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    }

}

export const getPriceSentimentData = async (requestBody) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify(requestBody),
        };

        const response = await fetch(`${baseUrl}/gethistdata3/`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const logoutApi = async () => {
    fetch(`${baseUrl}/logout2/`)
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
    window.location.href = '/sign_in'
}

export const getQuerySolrTranscripts = async (requestBody) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify(requestBody),
        };

        const response = await fetch(`${baseUrl}/querySolrTranscripts`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const getTranscriptContent = async (requestBody) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify(requestBody),
        };

        const response = await fetch(`${baseUrl}/getTranscriptContent`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const getRowMetadata = async (requestBody, checkedEntitlement) => {
    try {
        var url = '';
        if (isCallEnt(checkedEntitlement)) {
            url = `${baseUrl}/retrieveFromCassandra/${requestBody.id}/${requestBody.versionId}/${requestBody.userId}`;
        } else if (isWebNewsEnt(checkedEntitlement)) {
            url = `${baseUrl}/retrieveFromCassandraWebnews/${requestBody.id + '-' + requestBody.sectionId}/${requestBody.userId}`;
        } else if (isNewsEnt(checkedEntitlement)) {
            var id = requestBody.id.replace(/!/g, '');
            url = `${baseUrl}/retrieveFromCassandraNews/${id}/${requestBody.userId}`;
        }
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const annotationStorage = async (requestBody) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify(requestBody),
        };
        const response = await fetch(`${baseUrl}/annotationStorage`, requestOptions);
        if (!response.ok) {
            throw new Error('Request failed');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const searchCompanyName = async (name) => {
    if (name === '') {
        return null;
    }
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify({'keyword': name}),
        };
        const response = await fetch(`${baseUrl}/queryKeywordSolr`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const getAllEvents = async (sources) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify({'sources': sources}),
        };
        const response = await fetch(`${baseUrl}/getAllEvents`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const getEntityInfo = async (name) => {
    if (name === '') {
        return null;
    }
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify({'tickers': [name]}),
        };
        const response = await fetch(`${baseUrl}/getEntityInfo`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const postLoginInfo = async (email, password, rememberMe) => {
    if (process.env.NODE_ENV === "development") {
        return {status: true}
    }
    try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("username", email);
        urlencoded.append("password", password);
        urlencoded.append("remember_me", rememberMe);
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        const response = await fetch(`${baseUrl}/login_auth/`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const checkUserSession = async () => {
    if (process.env.NODE_ENV === "development") {
        return true
    } else {
        try {
            console.log("checking user login status")
            const response = await fetch(`${baseUrl}/user/is_login/`);
            console.log(response)
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const data = await response.json();
            return data.status
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
}



